import React, { useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { numberFormat, onlyNumber } from '../../../Utils/Common';
import moment from 'moment';
import ReturnUpload from '../Returns.global.upload';
import {
    handleInputChange,
    updateQty,
    submitReturn,
    resetModalInputs,
} from '../../../redux/actions/returns/return.actions';

function ReturnStockModal({
    return_stock_logs: { new_uploads, files_to_remove, qty, remarks },
    show,
    onHide,
    modalData,
    updateQty,
    submitReturn,
    handleInputChange,
    resetModalInputs,
}) {
    // Reset modal inputs when opening
    useEffect(() => {
        if (show) {
            resetModalInputs();
        }
    }, [show, resetModalInputs]);

    if (!show) return null;

    const data = modalData;

    const onFilesSelected = (files) => {
        handleInputChange({ target: { name: 'new_uploads', value: files } });
    };
    return (
        <Modal show={show} onHide={onHide} size="md" centered>
            <Modal.Header closeButton>
                <Modal.Title>Process Return</Modal.Title>
            </Modal.Header>
            <form onSubmit={(e) => submitReturn(e)}>
                <Modal.Body>
                    {data ? (
                        <>
                            <div className="container">
                                {/* Stock log information */}
                                <div className="mb-2">
                                    <div>
                                        <b>Movement:</b>{' '}
                                        <span>{data.movement}</span>
                                    </div>
                                    <div>
                                        <b>Quantity:</b>{' '}
                                        <span>{data.quantity}</span>
                                    </div>
                                    <div>
                                        <b> ID: </b>
                                        <span>
                                            {data._id.slice(0, 3)}...
                                            {data._id.slice(-3)}
                                        </span>
                                    </div>
                                    <div>
                                        <b>Date: </b>
                                        <span>
                                            {moment(data.date).format(
                                                'MM/DD/YYYY'
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="row align-items-start mb-2">
                                    <div className="col">
                                        <b>SKU</b>
                                        <div>{data.sku}</div>
                                    </div>
                                    <div className="col">
                                        <b>Product Name</b>
                                        <div>{data.product_name}</div>
                                    </div>
                                    <div className="col">
                                        <b>Description</b>
                                        <div>{data.description}</div>
                                    </div>
                                </div>
                                <div className="row align-items-start mb-2">
                                    <div className="col">
                                        <b>Reference Type</b>
                                        <div>{data.reference_type}</div>
                                    </div>
                                    <div className="col">
                                        <b>Reference No.</b>
                                        <div>{data.reference_no}</div>
                                    </div>
                                    <div className="col">
                                        <b>Supplier</b>
                                        <div>{data.supplier}</div>
                                    </div>
                                </div>
                            </div>

                            {/* Quantity adjustment controls */}
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        Adjust Return Quantity
                                    </h5>
                                    <div className="d-flex justify-content-center mb-2">
                                        <b className="mr-2">Quantity:</b>{' '}
                                        {/* Adds space to the right of label */}
                                        <div className="input-group w-50">
                                            <div className="input-group-prepend">
                                                <button
                                                    onClick={() =>
                                                        updateQty('dec')
                                                    }
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                >
                                                    <i className="ft ft-minus"></i>
                                                </button>
                                            </div>
                                            <input
                                                onChange={handleInputChange}
                                                onKeyPress={(e) =>
                                                    onlyNumber(e)
                                                }
                                                name="qty"
                                                value={qty}
                                                type="number"
                                                className="form-control"
                                                min="0"
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    onClick={() =>
                                                        updateQty('inc')
                                                    }
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                >
                                                    <i className="ft ft-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <b>Remarks:</b>
                                        <textarea
                                            rows="2"
                                            placeholder="Remarks"
                                            name="remarks"
                                            value={remarks}
                                            onChange={handleInputChange}
                                            className="form-control f-regular mt-2"
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="mt-3">
                                        <div>
                                            <b>File Upload:</b>
                                            <ReturnUpload
                                                onFilesSelected={
                                                    onFilesSelected
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        'No data available'
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Save Return
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    show: state.returns.return_stock_modal,
    modalData: state.returns.modalData,
    return_stock_logs: state.returns.return_stock_logs,
});

export default connect(mapStateToProps, {
    handleInputChange,
    updateQty,
    submitReturn,
    resetModalInputs,
})(ReturnStockModal);
