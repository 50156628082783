import * as TYPE from '../../types';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from 'jquery';
import {
    SwalWarning,
    SwalWarningForm,
    SwalWarningStock,
    SwalWarningTransaction,
} from '../../../Services/_swal.service';
import { POSService } from './_service.pos';
import { Toast } from 'react-bootstrap';
import { getNumRows } from '../../../Utils/Common';

const spinLG =
    '<span class="spinner-border" role="status" aria-hidden="true"></span>';

// Search
export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: TYPE.SEARCH_INPUT, payload: e.target.value });
};

// POS ITEM CLEARED
export const posItemClear = () => async (dispatch) =>
    dispatch({ type: TYPE.POS_ITEM_CLEAR });

// modal
export const setModal =
    (modal, status = true) =>
    async (dispatch) => {
        // if(!status) return dispatch({ type: TYPE.POS_CLEAR }); // clear reducer data
        dispatch({ type: TYPE.SET_MODAL, payload: { modal, status } });
    };

// set loading
export const setMiniLoading = (status) => async (dispatch) =>
    dispatch({ type: TYPE.SET_MINI_LOADING, payload: status });
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: TYPE.SET_LOADING, payload: status });

export const posLoading = (el) => async (dispatch) => {
    dispatch({ type: TYPE.POS_LOADING, payload: el });
};

// handle Select Customer
export const selectCustomer = (selectedOption) => async (dispatch) => {
    // console.log(selectedOption)
    dispatch({ type: TYPE.POS_SELECT_CUSTOMER, payload: selectedOption });
};

// handle qty item pos
export const updateQty = (status) => async (dispatch, getState) => {
    const { qty, pos_item } = getState().pos;
    let payload = status == 'inc' ? qty + 1 : qty == 1 ? qty : qty - 1;

    dispatch({ type: TYPE.POS_UPDATE_QTY, payload });
    dispatch(computeTotalPrice());
};

export const updateTransactionItemQty =
    (status) => async (dispatch, getState) => {
        const { qty, pos_item } = getState().pos;
        let payload = status == 'inc' ? qty + 1 : qty > 0 ? qty - 1 : 0;

        dispatch({ type: TYPE.POS_UPDATE_QTY, payload });
        dispatch(computeTotalPrice());
    };

export const getProducts = (products) => async (dispatch) => {
    dispatch({ type: TYPE.POS_GET_PRODUCTS, payload: { products: products } });
    dispatch(setModal('pos_item_modal'));
};

// handle inputs
export const handleInputChange = (e) => async (dispatch, getState) => {
    let { name, value } = e.target;
    const { pos_transaction_edit } = getState().pos;
    // console.log('e.target', e.target);

    // If the value is empty or NaN, set it to 0
    if (name != 'search') {
        value = value.trim() === '' || isNaN(value) ? 0 : value;
    }

    // Convert to correct number type
    value =
        name === 'qty'
            ? parseInt(value)
            : name === 'item_discount' ||
              name === 'pos_discount' ||
              name === 'pos_delivery_fee'
            ? parseFloat(value)
            : value;

    if (pos_transaction_edit === 'transaction') {
        const pos_transaction_edit = 'update_item';
        let payload = {
            pos_transaction_edit,
            // pos_transaction_edit: 'item_list'
        };
        dispatch({ type: TYPE.POS_TRANSACTION_EDIT, payload });
    }

    dispatch({ type: TYPE.POS_HANDLE_INPUT, payload: { name, value } });

    // Trigger computations only when relevant fields change
    if (['qty', 'item_discount'].includes(name)) {
        dispatch(computeTotalPrice()); // Per item computation
    }

    if (['pos_discount', 'pos_delivery_fee'].includes(name)) {
        dispatch(computeOverAllPOS()); // POS overall computation
    }
};

export const handleUpdateInputChange = (e) => async (dispatch, getState) => {
    const { pos_item } = getState().pos;

    let { name, value } = e.target;
    value =
        name == 'qty'
            ? parseInt(e.target.value)
            : name == 'item_discount' || name == 'pos_discount'
            ? parseFloat(e.target.value)
            : e.target.value;

    if (value > pos_item[0].order_qty) {
        ToastDanger('Quantity must not be greater than current order quantity');
        dispatch({
            type: TYPE.POS_HANDLE_INPUT,
            payload: { name, value: pos_item[0].order_qty },
        });
    } else {
        dispatch({ type: TYPE.POS_HANDLE_INPUT, payload: { name, value } });
    }

    // when to compute conditions
    (name == 'qty' || name == 'item_discount') && dispatch(computeTotalPrice()); // per item computation
    (name == 'pos_discount' || name == 'pos_delivery_fee') &&
        dispatch(computeOverAllPOS()); // pos over all
};

// handle discount type
export const discountType =
    (e, origin = null) =>
    async (dispatch, getState) => {
        let { name, value } = e.target;

        let payload = {
            name, //  item_discount || pos_discount_type
            value, // percent || amount || senior_pwd
            discount: value === 'senior' || value === 'pwd' ? 20 : 0, // if senior 20% if not 0
        };

        dispatch({ type: TYPE.POS_DISCOUNT_TYPE, payload });
        // Compute Data
        origin == 'over-all'
            ? dispatch(computeOverAllPOS())
            : dispatch(computeTotalPrice());
    };

// Compute Total of Amount per Item
export const computeTotalPrice = () => async (dispatch, getState) => {
    let { pos_item, qty, discount_type, item_discount } = getState().pos;

    // Make sure qty, pos_item[0].srp, and item_discount are numbers
    qty = Number(qty);
    let srp = Number(pos_item[0].srp);
    item_discount = Number(item_discount);

    // console.log("DATA", getState().pos)
    // console.log("QTY", qty)
    const amount = srp * qty;

    // Compute discount only if qty and srp are valid numbers
    let total_discount =
        !isNaN(amount) && discount_type == 'percent'
            ? (amount * item_discount) / 100
            : item_discount;

    let item_total_price =
        (isNaN(amount) ? 0.0 : amount) -
        (isNaN(total_discount) ? 0 : total_discount);

    pos_item[0]['_total_item_price'] = item_total_price;
    let payload = pos_item;

    dispatch({ type: TYPE.POS_COMPUTE_TOTAL, payload });
};

// Compute post list
export const computeOverAllPOS = () => async (dispatch, getState) => {
    let {
        pos_item_list,
        pos_discount_type,
        pos_sub_total,
        pos_discount,
        pos_total,
        pos_delivery_fee,
    } = getState().pos;

    let _pos_sub_total = pos_item_list.reduce(
        (sub, item) => sub + item.amount,
        0
    );
    // Ensure pos_discount is 0 if pos_discount_type is 'none'
    let _pos_discount = pos_discount_type === 'none' ? 0 : pos_discount;
    let _pos_delivery_fee = parseFloat(pos_delivery_fee);
    let _pos_total_discount =
        pos_discount_type == 'percent' ||
        pos_discount_type == 'senior' ||
        pos_discount_type == 'pwd'
            ? (parseFloat(_pos_sub_total) * _pos_discount) / 100
            : parseFloat(_pos_discount);
    let _pos_total =
        _pos_sub_total +
        _pos_delivery_fee -
        (Number.isNaN(_pos_total_discount) ? 0 : _pos_total_discount);

    // console.log(_pos_total_discount);

    let payload = {
        pos_discount: _pos_discount,
        pos_delivery_fee: _pos_delivery_fee,
        pos_saving: _pos_total_discount,
        pos_sub_total: _pos_sub_total,
        pos_grand_total: _pos_total,
    };

    dispatch({ type: TYPE.POS_COMPUTE_OVER_ALL_POS, payload });
};

// search single item
export const searchSubmit =
    (e, status = null) =>
    async (dispatch, getState) => {
        e.preventDefault();

        const { search } = getState().pos;

        // Validations
        if (search == '') {
            return ToastDanger('Search field is required');
        }

        const postParams = { search };

        dispatch(searchProduct(postParams));
    };

// search dynamic values on product
export const searchProduct =
    (postParams, origin = null) =>
    async (dispatch, getState) => {
        // loading show depends on input or from modal
        origin == 'item-modal'
            ? dispatch(posLoading('item-modal'))
            : dispatch(posLoading('pos-search'));

        let { pos_item } = getState().pos;

        POSService.scanBarcode(postParams)
            .then((res) => {
                if (res.status == 200) {
                    const pos_data = res.data;

                    // if search result shows multiple products
                    if (pos_data.products) {
                        dispatch(getProducts(pos_data.products));
                        dispatch(setModal('pos_item_modal'));
                        return false;
                    }

                    // Check if in stock
                    if (!pos_data.inStock) {
                        dispatch(posLoading('#'));
                        return ToastDanger(
                            `${pos_data.product_name} is out of stock`
                        );
                    }

                    if (pos_data.product_image == null) {
                        pos_data['product_image'] = '/noimage.jpg';
                    }

                    if (pos_item.length > 0) {
                        // IF THE POS_ITEM ALREADY HAS A VALUE
                        pos_data['_total_item_price'] = pos_data['srp']; // add another object for total price
                        dispatch(addToList());
                    }

                    pos_data['_total_item_price'] = pos_data['srp']; // add another object for total price
                    let pos_item_arr = pos_item.concat(pos_data);

                    dispatch({
                        type: TYPE.POS_GET_SCAN,
                        payload: pos_item_arr,
                    });
                    dispatch(setModal('pos_item_modal', false));
                    pos_item.length > 0
                        ? dispatch(posLoading('pos-scan'))
                        : dispatch(posLoading('#'));
                }
            }) // add another object for total price
            .catch((error) => {
                dispatch(posLoading('#'));
                if (error.data.message) {
                    return ToastDanger(error.data.message);
                }
                console.log(error);
            });
    };

// Add to POS List
export const addToList = () => async (dispatch, getState) => {
    let { pos_item, qty } = getState().pos;

    let total_stock = pos_item[0].total_stock;
    let _total_item_price = pos_item[0]._total_item_price;
    let inStock = pos_item[0].inStock;

    // Validation checking
    if (!inStock) {
        ToastDanger(
            `You only have ${total_stock} stock(s) of ${pos_item[0].product_name}.`
        );
    }
    if (Number.isNaN(qty) || qty <= 0) {
        ToastDanger(`Quantity field is required.`);
    } else if (_total_item_price <= 0) {
        ToastDanger(`Please check your Total Price.`);
    } else {
        dispatch(posStore());
    }
};

// POS Store ITEM
export const posStore =
    (force = false) =>
    async (dispatch, getState) => {
        const {
            pos_transaction_id,
            pos_item,
            pos_item_list,
            qty,
            item_discount,
            discount_type,
            pos_discount,
            pos_discount_type,
            item_discount_type,
            pos_saving,
            pos_delivery_fee,
        } = getState().pos;

        try {
            dispatch(posLoading('pos-scan'));

            // console.log("POS", getState().pos)

            let formParams = {
                product_id: pos_item[0]._id,
                qty: qty,
                item_discount: item_discount,
                item_discount_type: discount_type,
                discount: pos_discount,
                discount_type: pos_discount_type,
                saving: pos_saving,
                force: force,
                delivery_fee: pos_delivery_fee,
            };
            const res = await POSService.posStore(formParams);

            dispatch(removeScan());

            // console.log("RES:", res)

            const payload = {
                pos_item_list: res.data.transaction.items,
                pos_transaction_id: res.data.transaction._id,
                pos_serial_no: res.data.transaction.serial_no,
                pos_sub_total: res.data.transaction.sub_total,
                pos_grand_total: res.data.transaction.grand_total,
            };

            dispatch({ type: TYPE.POS_ADD_TO_LIST, payload });
            // dispatch(posTransactionViewEdit(pos_transaction_id))
            dispatch(posLoading('#'));
        } catch (err) {
            if (
                err.data.message ===
                    'Stock is less than the requested quantity' &&
                !force
            ) {
                const stocks = err.data.stocks;
                const product_name = err.data.product_name;
                SwalWarningStock(
                    'Warning!',
                    `There are <strong>currently ${stocks} stocks</strong> left for the item <strong>${product_name}</strong> in the system.<br><br> Are you sure you want to sell more than the current stocks available?`
                ).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(posStore(true)); // If the user confirms, call posStore again but with force = true
                    } else {
                        dispatch(posLoading('#')); // or some other action to stop the loading
                    }
                });
            } else {
                ToastDanger('Network error, please reload the page.');
                dispatch(posLoading('#'));
                console.log(err);
            }
        }
    };

//  REMOVE SCAN DATA
export const removeScan = () => async (dispatch, getState) => {
    let { pos_item, pos_transaction_edit } = getState().pos;

    if (pos_item.length > 0) {
        pos_item.shift(); // if the pos_item has value remove the first
    }

    dispatch({ type: TYPE.POS_REMOVE_SCAN, payload: pos_item });

    if (pos_transaction_edit === 'transaction') {
        let payload = {
            pos_transaction_edit,
            // pos_transaction_edit: 'item_list'
        };
        dispatch({ type: TYPE.POS_TRANSACTION_EDIT, payload });
    }
};

// REMOVE TRANSACTION
export const posRemoveTransaction = (remarks) => async (dispatch, getState) => {
    let { pos_transaction_id, pos_remarks } = getState().pos;

    try {
        let formParams = {
            remarks,
        };

        dispatch(posLoading('transaction-view'));
        const res = await POSService.posRemoveTransaction(
            pos_transaction_id,
            formParams
        );

        ToastSuccess(res.data.message);
        dispatch({ type: TYPE.POS_REMOVE_TRANSACTION });
        dispatch(posDaily());
        dispatch(posTransactionChecker());
        dispatch(posLoading('#'));
    } catch (err) {
        ToastDanger(err.data.message);
        dispatch(posLoading('#'));
        console.log(err);
    }
};

// POS Warning Confirmation before execute actions
export const posWarningConfirmation =
    (type, data = null) =>
    async (dispatch, getState) => {
        let { pos_serial_no, pos_remarks } = getState().pos;
        // console.log("getState().pos", getState().pos)
        switch (type) {
            case 'remove-item':
                SwalWarning(
                    'Warning!',
                    `Are you sure you want to remove ${data.product_name} ?`,
                    () => dispatch(posUpdateList(data))
                );
                break;
            case 'cancel-transaction':
                SwalWarningTransaction(
                    'Warning!',
                    `Are you sure you want to cancel this transaction ${pos_serial_no} ?`,
                    (pos_remarks) => dispatch(posRemoveTransaction(pos_remarks))
                );
                break;
            case 'transaction':
                dispatch(toggleDrawer(false));
                SwalWarningForm(
                    'Warning!',
                    'Please provide a Passcode to edit this transaction.',
                    () =>
                        dispatch(
                            posTransactionViewEdit(data._id, 'transaction')
                        )
                );
                break;
            // case 'sales':
            //     dispatch(toggleDrawer(false));
            //     SwalWarningForm('Warning!', 'Please provide a Passcode to edit this transaction.', () => {
            //         dispatch(posTransactionViewEdit(data._id, 'sales'));
            //         ToastSuccess('Code correct, please proceed to the Scanning Page')
            //     });
            //     break;
            default:
                break;
        }
    };

// Update Pos lists
export const posUpdateList = (item) => async (dispatch, getState) => {
    let { pos_item_list, pos_transaction_id } = getState().pos;

    try {
        dispatch(posLoading('transaction-view'));

        const res = await POSService.posRemovePOSItem(item._id);

        let update_pos_item_list = pos_item_list.filter(
            (_item, key) => _item._id !== item._id
        ); // remove the item from reducer

        let payload = {
            pos_item_list: update_pos_item_list,
            pos_sub_total: res.data.sub_total,
            pos_grand_total: res.data.grand_total,
        };

        dispatch({ type: TYPE.POS_UPDATE_LIST, payload });
        ToastSuccess(res.data.message);
        dispatch(posDaily());
        dispatch(posTransactionViewEdit(pos_transaction_id));
        dispatch(posLoading('#'));
    } catch (err) {
        console.log(err);
        ToastDanger(`Server Error`);
        dispatch(posLoading('#'));
    }
};

// POS SALES LIST
export const posSalesList =
    (pageNumber, rows_per_page = null) =>
    async (dispatch, getState) => {
        try {
            let {
                pagination,
                sort_order_name,
                sort_order_direction,
                date_range_string,
            } = getState().pos;
            let rows_per_page_val =
                rows_per_page != null
                    ? rows_per_page
                    : getNumRows('pos.sales') || 10;

            // let searchPostParams = {
            //     activePage: pagination.activePage,
            //     page: pageNumber,
            //     rows_per_page: rows_per_page_val,
            //     sort_order_name: sort_order_name,
            //     sort_order_direction: sort_order_direction,
            //     range : date_range_string
            // }

            //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
            let searchPostParams = {
                options: {
                    page: pageNumber,
                    limit: rows_per_page_val,
                    sort_by: sort_order_name,
                    sort_order: sort_order_direction,
                    range: date_range_string,
                },
            };
            // console.log("searchPostParams", searchPostParams);
            dispatch(setLoading(true));
            const res = await POSService.posSalesList(searchPostParams);

            // let _pagination = {
            //     totalCount: res.data.pos_sales.total,
            //     activePage: res.data.pos_sales.current_page,
            //     itemsCountPerPage: res.data.pos_sales.per_page,
            //     totalItemsCount: res.data.pos_sales.total,
            // }

            //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
            let _pagination = {
                totalCount: res.data.total_docs,
                activePage: res.data.page,
                itemsCountPerPage: res.data.limit,
                totalItemsCount: res.data.total_docs,
            };

            // console.log(res);
            dispatch({
                type: TYPE.POS_SALES_LIST,
                payload: { pos_sales: res.data.docs, pagination: _pagination },
            });
            dispatch(setLoading(false));
        } catch (err) {
            ToastDanger(`Server Error`);
            console.log(err);
        }
    };

// Sort by column name and direction
export const sortTableByColumn =
    (sort_order_name, sort_order_direction) => async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: TYPE.SORT_BY_COLUMN, payload: sortingParams });
        dispatch(posSalesList());
    };

// POS DAILY
export const posDaily =
    (id = null, pageNumber, rows_per_page = null, _new = true) =>
    async (dispatch, getState) => {
        let {
            pagination,
            sort_order_name,
            sort_order_direction,
            transaction_search,
        } = getState().pos;

        let rows_per_page_val =
            rows_per_page != null ? rows_per_page : getNumRows('pos') || 10;

        // console.log("POS", getState().pos)

        if (_new) {
            transaction_search = '';
            dispatch({
                type: TYPE.TRANSACTION_SEARCH,
                payload: transaction_search,
            });
        }
        try {
            dispatch(setLoading(true));

            // let searchPostParams = {
            //     id: id, // for per transaction daily (nullable)
            //     activePage: pagination.activePage,
            //     page: pageNumber,
            //     rows_per_page: rows_per_page,
            //     sort_order_name: sort_order_name,
            //     sort_order_direction: sort_order_direction,
            //     search : transaction_search
            // }

            //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
            let searchPostParams = {
                id: id,
                search: transaction_search,
                options: {
                    page: pageNumber,
                    limit: rows_per_page_val,
                    sort_by: sort_order_name,
                    sort_order: sort_order_direction,
                },
            };

            // call api for daily (today) transaction OR per pos daily transaction
            let res = id
                ? await POSService.posDailyView(searchPostParams)
                : await POSService.posDaily(searchPostParams);

            // console.log("RES", res)

            let payload = {
                id: id ? res.data.daily._id : '',
                pos_daily: res.data.daily,
                pos_cutoff: id ? res.data.cutoff[0] : res.data.cutoff,
                pos_cut_off_transactions: res.data.transaction.data,
                pos_user: id ? res.data.cutoff[0].user : res.data.cutoff.user,
                pagination: {
                    totalCount: res.data.transaction.total_docs,
                    activePage: res.data.transaction.page,
                    itemsCountPerPage: res.data.transaction.limit,
                    totalItemsCount: res.data.transaction.total_docs,
                },
            };

            // console.log("PAYLOAD", payload)

            dispatch({ type: TYPE.POS_SALES_DAILY, payload });
            dispatch(setLoading(false));
        } catch (err) {
            if (err.data.message) {
                ToastDanger(err.data.message);
            } else {
                ToastDanger('Server Error');
            }
            console.log(err);
        }

        dispatch(setLoading(false));
    };

// [PAY button]  POS CLOSE TRANSACTION API
export const posCloseTransaction =
    (callback = () => {}) =>
    async (dispatch, getState) => {
        let {
            pos_transaction_id,
            pos_remarks,
            pos_discount,
            discount_type,
            pos_grand_total,
            pos_saving,
            pos_delivery_fee,
            pos_serial_no,
            pos_item_list,
        } = getState().pos;

        let pos_total_qty = pos_item_list.reduce(
            (total, item) => total + item.order_qty,
            0
        );
        // console.log('getState().pos;', getState().pos);
        try {
            $('.btn-trans').attr('disabled', 'disabled').html(spinLG);

            let params = {
                id: pos_transaction_id,
                remarks: pos_remarks,
                pos_discount_type: discount_type,
                pos_grand_total,
                pos_saving: pos_saving ? pos_saving : 0,
                pos_delivery_fee: pos_delivery_fee ? pos_delivery_fee : 0,
                pos_discount,
                pos_total_qty,
                pos_serial_no,
            };

            const res = await POSService.posCloseTransaction(params);

            ToastSuccess(res.data.message);
            dispatch(posDaily());
            dispatch(posTransactionChecker());
            // dispatch(posSalesList());
            dispatch(clearPOSTransaction());
            $('.btn-trans').removeAttr('disabled').html('PAY');

            callback();
        } catch (err) {
            ToastDanger(`Server Error`);
            console.log(err);
            $('.btn-trans').removeAttr('disabled').html('PAY');

            callback();
        }
    };

// CLEAR POS TRANSACTION DATA
export const clearPOSTransaction = () => async (dispatch) =>
    dispatch({ type: TYPE.POS_CLEAR });

// POS Transaction Checker
export const posTransactionChecker = () => async (dispatch) => {
    try {
        const res = await POSService.posTransactionChecker();

        let transaction = res.data.transaction;
        // console.log("transaction", transaction);
        let payload = {
            pos_item_list: transaction ? transaction.items : null,
            pos_discount: transaction ? transaction.discount : 0,
            pos_discount_type:
                transaction && transaction.discount_type
                    ? transaction.discount_type
                    : 'none',
            pos_delivery_fee: transaction ? transaction.delivery_fee : 0,
            pos_sub_total: transaction ? transaction.sub_total : null,
            pos_grand_total: transaction ? transaction.grand_total : null,
            pos_transaction_id: transaction ? transaction._id : null,
            pos_serial_no: transaction ? transaction.serial_no : null,
            pos_transaction_edit: null,
        };

        dispatch({ type: TYPE.POS_TRANSACTION_VIEW, payload });
    } catch (err) {
        console.log(err);
        ToastDanger(`Server Error`);
    }
};

// Cancel edit Transaction View
export const cancelPosTransactionView = (origin) => async (dispatch) => {
    dispatch({ type: TYPE.POS_CANCEL_TRANSACTION_VIEW });

    origin === 'pos-edit' && dispatch(posTransactionChecker());
};

// posTransactionSingle
export const posTransactionView =
    (id, origin, rowIndex = null) =>
    async (dispatch) => {
        origin === 'receipt' && dispatch(cancelPosTransactionView(origin));

        try {
            dispatch(posLoading('transaction-view'));
            dispatch(posItemClear());

            const res = await POSService.posTransactionView(id);
            let transaction = res.data.transaction;

            let pos_discount_type = transaction.discount_type;
            let subtotal = transaction.sub_total;
            let pos_discount = transaction.discount;
            let _pos_total_discount =
                pos_discount_type === 'percent' ||
                pos_discount_type === 'senior' ||
                pos_discount_type === 'pwd'
                    ? (parseFloat(subtotal) * pos_discount) / 100
                    : parseFloat(pos_discount);
            // console.log("discount_amount", transaction.discount_amount);
            let payload = {
                pos_item_list: transaction.items,
                pos_discount: transaction.discount,
                pos_saving: _pos_total_discount,
                pos_sub_total: transaction.sub_total,
                pos_grand_total: transaction.grand_total,
                pos_transaction_id: transaction._id,
                pos_serial_no: transaction.serial_no,
                subs_setting: res.data.subs_setting,
                pos_created_at: transaction.created_at,
                pos_transaction_edit: origin,
                pos_discount_type: transaction.discount_type, // over all discount-type
                pos_discount: transaction.discount, // over all discount,
                selected_row: rowIndex, // selected row to be hightlighted
                pos_discount_amount: transaction.discount_amount,
            };

            dispatch({ type: TYPE.POS_TRANSACTION_VIEW, payload });
            dispatch(posLoading('#'));
        } catch (err) {
            console.log(err);
            ToastDanger(`Server Error`);
            dispatch(posLoading('#'));
        }
    };

// posTransactionSingle
export const posTransactionViewEdit =
    (id, origin, rowIndex = null) =>
    async (dispatch, getState) => {
        let { pos_transaction_edit } = getState().pos;

        if (origin === 'receipt') dispatch(cancelPosTransactionView(origin));

        try {
            dispatch(posLoading('transaction-view'));
            dispatch(posItemClear());

            const res = await POSService.posTransactionViewEdit(id);
            let transaction = res.data.transaction;

            // console.log('transaction', transaction);

            let _pos_delivery_fee = transaction.delivery_fee || 0;
            let pos_discount_type = transaction.discount_type || 'none';
            let subtotal = transaction.sub_total || 0;
            let pos_discount = transaction.discount || 0;

            let _pos_total_discount =
                pos_discount_type === 'percent' ||
                pos_discount_type === 'senior' ||
                pos_discount_type === 'pwd'
                    ? (parseFloat(subtotal) * pos_discount) / 100
                    : parseFloat(pos_discount);

            let payload = {
                pos_item_list: transaction.items || [],
                pos_discount: pos_discount,
                pos_saving: _pos_total_discount,
                pos_delivery_fee: _pos_delivery_fee,
                pos_sub_total: subtotal,
                pos_grand_total: transaction.grand_total || 0,
                pos_transaction_id: transaction._id,
                pos_serial_no: transaction.serial_no || '',
                subs_setting: res.data.subs_setting || {},
                pos_created_at: transaction.created_at || '',
                pos_transaction_edit: origin,
                pos_discount_type: pos_discount_type,
                pos_discount_amount: transaction.discount_amount || 0,
                selected_row: rowIndex,
                is_delivery: _pos_delivery_fee > 0,
                is_discount: pos_discount_type !== 'none',
            };

            // console.log('payload', payload);

            dispatch({ type: TYPE.POS_TRANSACTION_VIEW, payload });

            if (
                pos_transaction_edit === 'transaction' ||
                (pos_transaction_edit === 'update_item' &&
                    origin !== 'transaction')
            ) {
                dispatch({
                    type: TYPE.POS_TRANSACTION_EDIT,
                    payload: { pos_transaction_edit: 'update_item' },
                });
            }

            dispatch(posLoading('#'));
        } catch (err) {
            console.log(err);
            ToastDanger(`Server Error`);
            dispatch(posLoading('#'));
        }
    };

// POS Edit item from list
export const posEditTransactionItem = (index) => async (dispatch, getState) => {
    let { pos_item_list, qty, pos_transaction_edit } = getState().pos;

    let pos_item = {
        srp: pos_item_list[index].srp,
        cost: pos_item_list[index].cost,
        _id: pos_item_list[index]._id,
        product_name: pos_item_list[index].product_name,
        sku: pos_item_list[index].sku,
        barcode: pos_item_list[index].barcode,
        brand_name: pos_item_list[index].brand_name,
        brand_id: pos_item_list[index].brand_id,
        order_qty: pos_item_list[index].order_qty,
        _total_item_price: pos_item_list[index].srp,
        product_image: pos_item_list[index].product_image,
    };

    let _pos_item = [];
    _pos_item.push(pos_item);

    if (pos_transaction_edit !== 'transaction') {
        pos_transaction_edit =
            pos_transaction_edit === null ? 'item_list' : pos_transaction_edit;
    }

    let payload = {
        pos_item: _pos_item,
        qty: pos_item_list[index].order_qty,
        discount_type: pos_item_list[index].discount_type,
        item_discount: pos_item_list[index].discount_item,
        pos_transaction_edit,
        // pos_transaction_edit: 'item_list'
    };
    dispatch({ type: TYPE.POS_EDIT_TRANSACTION_ITEM, payload });
};

// POS TRANSACTION ITEM UPDATE
export const posUpdateTransactionItem = () => async (dispatch, getState) => {
    let {
        qty,
        item_discount,
        discount_type,
        pos_transaction_id,
        pos_item,
        pos_item_list,
        pos_transaction_edit,
        pos_discount_type,
        pos_discount,
        pos_saving,
        pos_delivery_fee,
    } = getState().pos;

    // console.log('getState().pos;', getState().pos);

    try {
        dispatch(posLoading('pos-scan'));

        let params = {
            id: pos_item[0]._id, // item_id
            item_discount,
            item_discount_type: discount_type,
            qty,
            pos_discount_type,
            pos_discount,
            pos_saving,
            pos_delivery_fee,
            pos_transaction_id,
        };
        // console.log('PARAMS:', params)
        await POSService.posUpdateTransactionItem(params);
        // console.log('posUpdateTransactionItem', pos_transaction_edit)

        ToastSuccess('Item has been updated successfully.');
        dispatch(posItemClear());
        dispatch(posTransactionViewEdit(pos_transaction_id, ''));
        pos_transaction_edit == 'transaction' && dispatch(posDaily());
        dispatch(posLoading('#'));
    } catch (err) {
        console.log(err);
        ToastDanger(`Server Error`);
        dispatch(posLoading('#'));
    }
};

// Update Transaction
export const posUpdateTransaction = () => async (dispatch, getState) => {
    let { pos_transaction_id, pos_discount, pos_discount_type, pos_remarks } =
        getState().pos;

    try {
        dispatch(posLoading('transaction-view'));

        let params = {
            id: pos_transaction_id,
            remarks: pos_remarks,
            pos_discount,
            pos_discount_type,
        };

        let res = await POSService.posUpdateTransaction(params);

        console.log(res);
        ToastSuccess(res.data.message);
        dispatch(posTransactionViewEdit(pos_transaction_id, 'transaction'));
        dispatch(posLoading('#'));
    } catch (err) {
        console.log(err);
        ToastDanger(`Server Error`);
        dispatch(posLoading('#'));
    }
};

// export add discount
export const addDiscount = (status) => async (dispatch) =>
    dispatch({ type: TYPE.POS_ADD_DISCOUNT, payload: status });

// export add deliovery
export const addDelivery = (status) => async (dispatch) =>
    dispatch({ type: TYPE.POS_ADD_DELIVERY, payload: status });

// Toggle Transaction History list
export const toggleDrawer = (open) => async (dispatch, getState) => {
    let { toggle_list } = getState().pos;
    toggle_list['open'] = open;

    // console.log(toggle_list)
    dispatch({ type: TYPE.POS_TOGGLE_LIST, payload: toggle_list });
};

export const DateRange = (event, picker) => async (dispatch, getState) => {
    // console.log("picker", picker)
    let { date_range_string } = getState().pos;
    let range = {
        startDate: picker.startDate,
        endDate: picker.endDate,
    };
    let range_string = {
        startDate: picker.startDate.format('YYYY-MM-DD'),
        endDate: picker.endDate.format('YYYY-MM-DD'),
    };
    dispatch({
        type: TYPE.DATE_RANGE,
        payload: { moment: range, str: range_string },
    });
};

export const DateCallBack =
    (start, end, page, rowsPerPage) => async (dispatch) => {
        let range = {
            startDate: start,
            endDate: end,
        };
        let range_string = {
            startDate: start.format('YYYY-MM-DD'),
            endDate: end.format('YYYY-MM-DD'),
        };
        dispatch({
            type: TYPE.DATE_RANGE,
            payload: { moment: range, str: range_string },
        });
        dispatch(posSalesList(page + 1, rowsPerPage));
    };
export const paginateChange = (value, type) => async (dispatch, getState) => {
    // console.log("value", value);
    let { pagination } = getState().pos;
    // pagination[type] = value;
    // dispatch({type : TYPE.PAGINATION, payload : pagination})
};

export const searchTransaction =
    (e, options, pos_daily_id) => async (dispatch) => {
        // , rowsPerPage, page

        dispatch({ type: TYPE.TRANSACTION_SEARCH, payload: e.target.value });
        dispatch(
            posDaily(pos_daily_id, options.page, options.rowsPerPage, false)
        );
    };

export const clearDate = (page, rowsPerPage) => async (dispatch) => {
    let range = {
        startDate: new Date(),
        endDate: new Date(),
    };
    let range_string = {
        startDate: '',
        endDate: '',
    };
    dispatch({
        type: TYPE.DATE_RANGE,
        payload: { moment: range, str: range_string },
    });
    dispatch(posSalesList(page + 1, rowsPerPage));
};
