import React, { Fragment, useEffect } from 'react';
import Spinner, { MiniSpinner } from '../../components/layouts/Spinner';
import { connect } from 'react-redux';
import {
    handleInputChange,
    brandUpdate,
    getSingleBrand,
} from '../../redux/actions/brand/brand.actions';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions';

function BrandEdit({
    brand: { single_brand, main_brand_page, main_url, loading },
    handleInputChange,
    brandUpdate,
    match,
    getSingleBrand,
    block_route,
    blockRoute,
}) {
    const history = useHistory();

    useEffect(() => {
        getSingleBrand(match.params.id);
        blockRoute(false);
    }, []);

    useEffect(() => {
        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () =>
        window.history.pushState(null, null, window.location.pathname);

    if (main_brand_page) return <Redirect to={main_url} />;

    return (
        <BeforeUnloadComponent
            ignoreBeforeunloadDocument={true}
            blockRoute={block_route}
            modalComponentHandler={({
                handleModalLeave,
                handleModalCancel,
            }) => {
                SwalFormWarning(
                    'Warning',
                    'Do you want to leave without saving',
                    () => handleModalLeave(),
                    () => handleModalCancel()
                );
            }}
        >
            <div className="app-content content">
                <div className="content-wrapper max-1920 mx-auto">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Product
                            </h3>
                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb f-regular">
                                        <li className="breadcrumb-item">
                                            <a
                                                href={main_url}
                                                className="text-white"
                                            >
                                                Brands
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="#" className="text-white">
                                                Edit
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body margin-top-5">
                        <section id="basic-examples">
                            <div className="row f-regular">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header mb-0 pb-0">
                                            <Link to={main_url}>
                                                <i className="ft ft-arrow-left mb-2"></i>{' '}
                                                Back
                                            </Link>
                                        </div>

                                        <div className="card-content collapse show">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        {loading ? (
                                                            <MiniSpinner />
                                                        ) : (
                                                            <form
                                                                onSubmit={
                                                                    brandUpdate
                                                                }
                                                            >
                                                                <ReactTooltip
                                                                    effect="solid"
                                                                    event="click"
                                                                />
                                                                <section className="row">
                                                                    <div className="col-md-12">
                                                                        <label className="label-control f-regular">
                                                                            Name:{' '}
                                                                            <span className="text-danger">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-11 col-10">
                                                                                <input
                                                                                    onChange={
                                                                                        handleInputChange
                                                                                    }
                                                                                    value={
                                                                                        single_brand.name
                                                                                    }
                                                                                    type="text"
                                                                                    name="name"
                                                                                    className="form-control"
                                                                                    placeholder="Name"
                                                                                    required
                                                                                />
                                                                            </div>
                                                                            <div className="pl-0 col-1 col-md-1">
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <label className="label-control f-regular">
                                                                            Description:{' '}
                                                                        </label>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-11 col-10">
                                                                                <textarea
                                                                                    onChange={
                                                                                        handleInputChange
                                                                                    }
                                                                                    value={
                                                                                        single_brand.description
                                                                                    }
                                                                                    name="description"
                                                                                    rows="3"
                                                                                    className="form-control"
                                                                                    placeholder="Description"
                                                                                ></textarea>
                                                                            </div>
                                                                            <div className="pl-0 col-1 col-md-1">
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-4 col-12 label-control f-regular"></div>
                                                                            <div className="col-md-7 col-10 text-right">
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary btn-save "
                                                                                >
                                                                                    Save
                                                                                    Brand
                                                                                </button>
                                                                            </div>
                                                                            <div className="pl-0 col-2 col-md-1"></div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </form>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </BeforeUnloadComponent>
    );
}

const mapStateToProps = (state) => ({
    brand: state.brand,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    handleInputChange,
    brandUpdate,
    getSingleBrand,
    blockRoute,
})(BrandEdit);
