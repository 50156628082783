import React, { Fragment, useEffect, useState } from 'react';
import Spinner from '../../layouts/Spinner';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { customAlert } from '../../../Utils/Confirm';
import InventoryList from '.././Inventory.list';
import { handleChangeStockView } from '../../../redux/actions/inventory/inventory.actions';
import { Show, useAccess } from 'react-redux-permission';
import { Link, useParams, useLocation } from 'react-router-dom';
import ProductList from '../../product/Product.list';
import Bundle from '../../bundle/Bundle';
import BrandList from '../../brand/Brand.list';
import AttributeList from '../../attribute_template/AttributeTemplate.list';
import CategoryList from '../../category/Category.list';
import { setMUIPage } from '../../../redux/actions/helper/helper.actions';

function InventoryProduct({
    inventory: { inventory_list },
    handleChangeStockView,
    match,
    setMUIPage,
    muiPage,
}) {
    let params = new URLSearchParams(useLocation().search);
    const [selectedTab, SetSelectedTab] = useState('Items');
    const [tabs, setTabs] = useState([
        {
            key: 'Items',
            class: 'nav-link inv-top-nav inv-top-nav-active',
            _status: 1,
            permission: 'inv.products.items.view',
        },
        {
            key: 'Bundle',
            class: 'nav-link inv-top-nav',
            _status: 0,
            permission: 'inv.products.bundle.view',
        },
        {
            key: 'Brands',
            class: 'nav-link inv-top-nav',
            _status: 0,
            permission: 'inv.products.brands.view',
        },
        {
            key: 'Attributes',
            class: 'nav-link inv-top-nav',
            _status: 0,
            permission: 'inv.products.attribute-template.view',
        },
        {
            key: 'Categories',
            class: 'nav-link inv-top-nav',
            _status: 0,
            permission: 'inv.products.categories.view',
        },
    ]);

    function handleChangeTab(tab) {
        setMUIPage(1);
        SetSelectedTab(tab);
        tabs.map((list) => {
            list.class =
                list.key == tab
                    ? 'nav-link inv-top-nav inv-top-nav-active'
                    : 'nav-link inv-top-nav';

            return list;
        });
        setTabs(tabs);
    }

    useEffect(() => {
        let tab = params.get('mode');

        if (tab) {
            handleChangeTab(tab);
            setMUIPage(muiPage);
        }
    }, []);

    // console.log(tabs);
    return (
        <div className="app-content content">
            <ReactTooltip effect="solid" event="click" />
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">
                            Inventory{' '}
                        </h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item ">
                                        <a href="#" className="text-white">
                                            Products
                                        </a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-body">
                    <section className="row f-regular">
                        <div className="col-sm-12">
                            <div className="card">
                                {/* <div className="card-header"></div> */}
                                <div className="card-content collapse show">
                                    <div className="card-body">
                                        <div className="">
                                            <nav class="nav f-regular nav-product-inv">
                                                {tabs.map((item) => (
                                                    <Show
                                                        when={item.permission}
                                                    >
                                                        <a
                                                            onClick={() =>
                                                                handleChangeTab(
                                                                    item.key
                                                                )
                                                            }
                                                            class={item.class}
                                                        >
                                                            {item.key}
                                                        </a>
                                                    </Show>
                                                ))}
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="row f-regular">
                        {(() => {
                            switch (selectedTab) {
                                case 'Items':
                                    return <ProductList />;
                                case 'Bundle':
                                    return <Bundle />;
                                case 'Brands':
                                    return <BrandList />;
                                case 'Attributes':
                                    return <AttributeList />;
                                case 'Categories':
                                    return <CategoryList />;
                                default:
                                    return null;
                            }
                        })()}
                    </section>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    inventory: state.inventory,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, { handleChangeStockView, setMUIPage })(
    InventoryProduct
);
