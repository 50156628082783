import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { numberFormat, onlyNumber } from '../../../Utils/Common';
import Spinner, { MiniSpinner } from '../../layouts/Spinner';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async'; // Added import for AsyncSelect
import {
    handleInputChange,
    updateQty,
    submitStockTransfer,
    resetModalInputs,
    searchSubmit,
    removeScan,
    setModal,
} from '../../../redux/actions/stock_transfer/stock_transfer.actions';

function MultiTransferModal({
    stock_transfer,
    show,
    onHide,
    updateQty,
    submitStockTransfer,
    handleInputChange,
    resetModalInputs,
    searchSubmit,
    removeScan,
    setModal,
}) {
    const [isTransfering, setIsTransfering] = useState(false);
    const [transferOption, setTransferOption] = useState(null); // State to control AsyncSelect

    // Updated handleSubmitTransfer to ensure the button re-enables after processing.
    // Ensure that your submitStockTransfer action returns a promise (or uses the callback)
    // so that we can reliably reset isTransfering.
    const handleSubmitTransfer = async (movement) => {
        setIsTransfering(true);
        try {
            // If submitStockTransfer returns a promise, we can await it.
            // Otherwise, if you're using the callback pattern, ensure that the callback is always invoked.
            await submitStockTransfer(movement);
        } catch (error) {
            console.error('Error processing transfer:', error);
        } finally {
            setIsTransfering(false);
        }
    };

    const { transfer_item, qty, remarks } = stock_transfer;

    // useEffect(() => {
    //     if (show && stock_transfer.suppliers.length === 0) {
    //         setModal('transfer_supplier_modal', true);
    //     }
    // }, [show, stock_transfer.suppliers, setModal]);

    // // Reset modal inputs when opening
    // useEffect(() => {
    //     if (show) {
    //         resetModalInputs();
    //     }
    // }, [show, resetModalInputs]);

    if (!show) return null;

    // Function to remove a scanned item
    const removeScannedItem = (index) => {
        let updatedItems = stock_transfer.transfer_item.filter(
            (_, i) => i !== index
        );
        handleInputChange({
            target: { name: 'transfer_item', value: updatedItems },
        });
    };

    // Function to load options asynchronously for the AsyncSelect component
    const loadOptions = (inputValue, callback) => {
        const options = [
            { label: 'In', value: 'in' },
            { label: 'Out', value: 'out' },
        ];
        callback(options);
    };

    // Handler for when a transfer option is selected from the dropdown.
    // Once selected, process the transfer and then reset the selected option.
    const handleTransferChange = (selectedOption) => {
        setTransferOption(selectedOption);
        if (selectedOption) {
            handleSubmitTransfer(selectedOption.value);
            setTransferOption(null); // Reset the select value after submission
        }
    };

    // Custom styles for AsyncSelect to mimic a primary button appearance and show full text
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100px', // Fixed width to ensure the full placeholder ("Transfer") is visible
        }),
        control: (provided, state) => ({
            ...provided,
            minHeight: '38px',
            height: '38px',
            borderRadius: '4px',
            backgroundColor: '#6967CE', // Bootstrap primary background color
            border: '1px solid #6967CE',
            boxShadow: state.isFocused
                ? '0 0 0 0.2rem rgba(0,123,255,.25)'
                : null,
            cursor: 'pointer',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 6px',
            overflow: 'visible', // Ensure full text is visible
            justifyContent: 'center',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',
            color: '#fff',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#fff',
            fontSize: '16px',
            fontWeight: 600,
        }),
        indicatorSeparator: () => null,
        dropdownIndicator: () => null,
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '38px',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
    };

    // console.log('stock_transfer', stock_transfer);
    return (
        <Modal show={show} onHide={onHide} size="md" centered>
            <Modal.Header closeButton disabled={isTransfering}>
                <Modal.Title>Process Multi Transfer </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 mb-2 pr-2">
                    <div className="row">
                        <div className="row mb-1">
                            <label className="col-md-4 col-sm-12 pl-0 text-center align-self-center">
                                SKU/Barcode:{' '}
                            </label>
                            <div className="col-md-7 col-10 px-0">
                                <form onSubmit={(e) => searchSubmit(e, 'btn')}>
                                    <div className="input-group mb-0">
                                        <input
                                            type="text"
                                            id="search"
                                            value={stock_transfer.search}
                                            onChange={handleInputChange}
                                            name="search"
                                            className="form-control f-regular"
                                            placeholder="Search"
                                            required
                                            // disabled={
                                            //     stock_transfer.pos_transaction_edit ===
                                            //     'transaction'
                                            //         ? true
                                            //         : false
                                            // }
                                        />
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-sm btn-primary btn-z-index search-btn"
                                                type="submit"
                                            >
                                                {/* {stock_transfer.loading ==
                                                                    'stock_transfer-search' &&
                                                                stock_transfer.multi_products ==
                                                                    false ? (
                                                                    <span
                                                                        className="spinner-border spinner-border-sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                ) : ( */}
                                                <i className="ft ft-search"></i>
                                                {/* )} */}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-1 pr-0">
                                <div className="float-right">
                                    <div className="input-group-prepend">
                                        <span
                                            href="#"
                                            className="tool-tip text-primary"
                                            data-tip="this is a tool tip"
                                        >
                                            <i className="la la-question-circle"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {transfer_item.length > 0 ? (
                            <div className="col-12 card p-1">
                                <div className="card-header p-0">
                                    <h5 className="text-center">
                                        Scanned Items
                                    </h5>
                                </div>
                                <div className="card-body row p-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Product</th>
                                                <th>SKU</th>
                                                <th>Quantity</th>
                                                <th>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transfer_item.map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <img
                                                                src={
                                                                    item.product_image
                                                                }
                                                                className="img-thumbnail"
                                                                style={{
                                                                    width: '50px',
                                                                    height: '50px',
                                                                }}
                                                                alt={
                                                                    item.product_name
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <div className=" text-dark">
                                                                <Link
                                                                    className="txt-underline"
                                                                    to={`${'/inventory/view'}/${
                                                                        item._id
                                                                    }`}
                                                                >
                                                                    {
                                                                        item.product_name
                                                                    }
                                                                </Link>
                                                            </div>
                                                            <div className="f-12 ">
                                                                {
                                                                    item.description
                                                                }
                                                            </div>{' '}
                                                            <div className="f-12 ">
                                                                <Link
                                                                    className="txt-underline"
                                                                    to={`${'/inventory/products/brands/edit'}/${
                                                                        item.brand_id
                                                                    }`}
                                                                >
                                                                    {
                                                                        item.brand_name
                                                                    }
                                                                </Link>
                                                            </div>
                                                        </td>
                                                        <td>{item.sku}</td>
                                                        <td className="text-center">
                                                            {1}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() =>
                                                                    removeScannedItem(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <i className="ft ft-trash text-white"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="w-100 p-2">
                                        <b>Remarks:</b>
                                        <textarea
                                            rows="2"
                                            placeholder="Remarks"
                                            name="remarks"
                                            value={remarks}
                                            onChange={handleInputChange}
                                            className="form-control f-regular mt-2"
                                            required
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-12 card mb-0">
                                <div className="card-body row pos-blank-card">
                                    {/* Empty state message */}
                                </div>
                            </div>
                        )}
                    </div>{' '}
                </div>{' '}
            </Modal.Body>
            <Modal.Footer>
                {/* Transfer dropdown using AsyncSelect */}
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptions}
                    onChange={handleTransferChange}
                    value={transferOption}
                    placeholder="Transfer"
                    isDisabled={isTransfering}
                    styles={customStyles}
                    isSearchable={false}
                    menuPlacement="top" // Options pop up above the button
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }} // Remove arrow
                />
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    stock_transfer: state.stock_transfer,
    show: state.stock_transfer.multi_stock_transfer_modal,
});

export default connect(mapStateToProps, {
    handleInputChange,
    updateQty,
    submitStockTransfer,
    resetModalInputs,
    searchSubmit,
    removeScan,
    setModal,
})(MultiTransferModal);
