import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { numberFormat, onlyNumber } from '../../../Utils/Common';
import Spinner, { MiniSpinner } from '../../layouts/Spinner';
import moment from 'moment';
import AsyncSelect from 'react-select/async'; // Added import for AsyncSelect
import {
    handleInputChange,
    updateQty,
    submitStockTransfer,
    resetModalInputs,
    searchSubmit,
    removeScan,
} from '../../../redux/actions/stock_transfer/stock_transfer.actions';
import ItemModal from './itemModal';

function TransferModal({
    stock_transfer,
    show,
    onHide,
    updateQty,
    submitStockTransfer,
    handleInputChange,
    resetModalInputs,
    searchSubmit,
    removeScan,
}) {
    const [isTransfering, setIsTransfering] = useState(false);
    const [transferOption, setTransferOption] = useState(null); // State to control AsyncSelect

    // Updated handleSubmitTransfer using async/await to ensure the button re-enables after processing.
    const handleSubmitTransfer = async (movement) => {
        setIsTransfering(true);
        try {
            await submitStockTransfer(movement);
        } catch (error) {
            console.error('Error processing transfer:', error);
        } finally {
            setIsTransfering(false);
        }
    };

    const { transfer_item, qty, remarks } = stock_transfer;

    // // Reset modal inputs when opening
    // useEffect(() => {
    //     if (show) {
    //         resetModalInputs();
    //     }
    // }, [show, resetModalInputs]);

    if (!show) return null;

    const onFilesSelected = (files) => {
        handleInputChange({ target: { name: 'new_uploads', value: files } });
    };

    // Function to load options asynchronously for the AsyncSelect component
    const loadOptions = (inputValue, callback) => {
        const options = [
            { label: 'In', value: 'in' },
            { label: 'Out', value: 'out' },
        ];
        callback(options);
    };

    // Handler for when a transfer option is selected from the dropdown.
    // Once selected, process the transfer and then reset the selected option.
    const handleTransferChange = (selectedOption) => {
        setTransferOption(selectedOption);
        if (selectedOption) {
            handleSubmitTransfer(selectedOption.value);
            setTransferOption(null); // Reset the select value after submission
        }
    };

    // Custom styles for AsyncSelect to mimic a primary button appearance
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100px', // Fixed width to ensure the full placeholder ("Transfer") is visible
        }),
        control: (provided, state) => ({
            ...provided,
            minHeight: '38px',
            height: '38px',
            borderRadius: '4px',
            backgroundColor: '#6967CE', // Bootstrap primary background color
            border: '1px solid #6967CE',
            boxShadow: state.isFocused
                ? '0 0 0 0.2rem rgba(0,123,255,.25)'
                : null,
            cursor: 'pointer',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 6px',
            overflow: 'visible', // Ensure full text is visible\
            justifyContent: 'center',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',
            color: '#fff',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#fff',
            fontSize: '16px',
            fontWeight: 600,
        }),
        indicatorSeparator: () => null,
        dropdownIndicator: () => null,
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '38px',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
    };

    return (
        <Modal show={show} onHide={onHide} size="md" centered>
            <Modal.Header closeButton disabled={isTransfering}>
                <Modal.Title>Process Transfer </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 mb-2 pr-2">
                    <div className="row p-2">
                        <div className="row mb-1">
                            <label className="col-md-4 col-sm-12 pl-0 text-center align-self-center">
                                SKU/Barcode:{' '}
                            </label>
                            <div className="col-md-7 col-10 px-0">
                                <form onSubmit={(e) => searchSubmit(e, 'btn')}>
                                    <div className="input-group mb-0">
                                        <input
                                            type="text"
                                            id="search"
                                            value={stock_transfer.search}
                                            onChange={handleInputChange}
                                            name="search"
                                            className="form-control f-regular"
                                            placeholder="Search"
                                            required
                                            // disabled={
                                            //     stock_transfer.pos_transaction_edit ===
                                            //     'transaction'
                                            //         ? true
                                            //         : false
                                            // }
                                        />
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-sm btn-primary btn-z-index search-btn"
                                                type="submit"
                                            >
                                                {/* {stock_transfer.loading ==
                                                                    'stock_transfer-search' &&
                                                                stock_transfer.multi_products ==
                                                                    false ? (
                                                                    <span
                                                                        className="spinner-border spinner-border-sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                ) : ( */}
                                                <i className="ft ft-search"></i>
                                                {/* )} */}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-1 pr-0">
                                <div className="float-right">
                                    <div className="input-group-prepend">
                                        <span
                                            href="#"
                                            className="tool-tip text-primary"
                                            data-tip="this is a tool tip"
                                        >
                                            <i className="la la-question-circle"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {transfer_item.length > 0 ? (
                            <div className="col-12 card p-1">
                                <div className="card-header p-0">
                                    <div className="d-flex justify-content-end">
                                        <div>
                                            {transfer_item.loading ===
                                            'stock-transfer-scan' ? (
                                                ''
                                            ) : (
                                                <i
                                                    onClick={() => removeScan()}
                                                    className="ft ft-x ft-tran-icon font-weight-bold pos-close"
                                                ></i>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body row pt-0">
                                    {transfer_item.loading ===
                                    'stock-transfer-scan' ? (
                                        <div className="mx-auto my-4">
                                            <MiniSpinner />
                                        </div>
                                    ) : (
                                        <div className="row my-2">
                                            <div className="col-lg-12 col-12">
                                                <div className="row justify-content-between">
                                                    <div className="d-flex justify-content-center">
                                                        <div className="col-6">
                                                            <img
                                                                src={
                                                                    transfer_item[0]
                                                                        .product_image
                                                                }
                                                                className="img-fluid pos-img mt-0 pt-0"
                                                                alt="img"
                                                            />
                                                        </div>

                                                        <div className="col-6 d-flex justify-content-center align-items-center">
                                                            <div className="input-group-prepend">
                                                                <span
                                                                    onClick={() =>
                                                                        updateQty(
                                                                            'dec'
                                                                        )
                                                                    }
                                                                    className="input-group-text p-0 pos-btn-grey"
                                                                >
                                                                    <i className="ft ft-minus pos-qty-icon"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                onKeyPress={(
                                                                    e
                                                                ) =>
                                                                    onlyNumber(
                                                                        e
                                                                    )
                                                                }
                                                                name="qty"
                                                                value={
                                                                    transfer_item[0]
                                                                        .qty
                                                                }
                                                                type="number"
                                                                className="form-control form-control-lg f-regular"
                                                                min="1"
                                                            />
                                                            <div className="input-group-append">
                                                                <span
                                                                    onClick={() =>
                                                                        updateQty(
                                                                            'inc'
                                                                        )
                                                                    }
                                                                    className="input-group-text p-0 pos-btn-grey"
                                                                >
                                                                    <i className="ft ft-plus pos-qty-icon"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        {transfer_item[0]
                                                            .stockWarning && (
                                                            <span className="lbl-status lbl-lg lbl-pos-warning">
                                                                LOW THRESHOLD
                                                            </span>
                                                        )}
                                                        <div className="pos-strong-text pt-1">
                                                            {
                                                                transfer_item[0]
                                                                    .product_name
                                                            }
                                                        </div>
                                                        <div className="pos-description">
                                                            SKU:{' '}
                                                            {
                                                                transfer_item[0]
                                                                    .sku
                                                            }
                                                        </div>
                                                        <div className="pos-description">
                                                            Barcode:{' '}
                                                            {
                                                                transfer_item[0]
                                                                    .barcode
                                                            }
                                                        </div>
                                                        <div className="pos-description">
                                                            Brand:{' '}
                                                            {
                                                                transfer_item[0]
                                                                    .brand_name
                                                            }
                                                        </div>
                                                        <div className="pos-description mb-2">
                                                            SRP: P{' '}
                                                            {numberFormat(
                                                                transfer_item[0]
                                                                    .srp
                                                            )}
                                                        </div>

                                                        <div>
                                                            <b>Remarks:</b>
                                                            <textarea
                                                                rows="2"
                                                                placeholder="Remarks"
                                                                name="remarks"
                                                                value={remarks}
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                className="form-control f-regular mt-2"
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="col-12 card mb-0">
                                <div className="card-body row pos-blank-card">
                                    {/* THIS IS A EMPTY CARD */}
                                </div>
                            </div>
                        )}
                    </div>{' '}
                </div>{' '}
            </Modal.Body>
            <Modal.Footer>
                {/* Replace the two transfer buttons with the AsyncSelect dropdown */}
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptions}
                    onChange={handleTransferChange}
                    value={transferOption}
                    placeholder="Transfer"
                    isDisabled={isTransfering}
                    styles={customStyles}
                    isSearchable={false}
                    menuPlacement="top" // Options pop up above the button
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }} // Remove arrow
                />
            </Modal.Footer>

            {/* ITEM MODAL */}
            <ItemModal />
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    stock_transfer: state.stock_transfer,
    show: state.stock_transfer.stock_transfer_modal,
});

export default connect(mapStateToProps, {
    handleInputChange,
    updateQty,
    submitStockTransfer,
    resetModalInputs,
    searchSubmit,
    removeScan,
})(TransferModal);
