import * as TYPE from '../types';

const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 10,
        pageRangeDisplayed: 8,
    },
    subs_setting: null,
    mini_loading: false,
    loading: false,
    pos_serial_no: '',
    pos_created_at: '',
    pos_transaction_id: '',
    pos_sales: [],
    pos_daily: [], // pos_daily
    pos_cutoff: [], // pos_daily => cutoff
    pos_cut_off_transactions: [], // pos_daily => cutoff => transactions
    pos_user: [], // pos_daily => cutoff => user
    total_sales_today: 0,
    products: [],
    customer_option_value: {},
    customer_options: [],
    pos_item: [],
    pos_item_list: [],
    customer_id: '',
    search: '',
    qty: 1,
    item_discount: 0, // item discount
    discount_type: 'none', // item discount
    discount_type_option: [
        { value: 'none', label: 'None', disabled: false },
        { value: 'percent', label: 'Percent', disabled: false },
        { value: 'amount', label: 'Amount', disabled: false },
        // { value: 'senior', label: 'Senior', disabled: false },
        // { value: 'pwd', label: 'PWD', disabled: false },
    ],
    pos_discount_type: 'none', // pos transaction discount
    pos_discount: 0, // pos transaction discount,
    pos_discount_amount: 0,
    pos_saving: 0.0,
    pos_delivery_fee: 0,
    pos_sub_total: 0.0,
    pos_grand_total: 0.0,
    pos_remarks: '',
    pos_item_modal: false,
    multi_products: false,
    main_url: '/sales/pos',
    create_url: '/sales/pos/create',
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    set_row_checked: false,
    customer_modal: false,
    pos_loading: '#',
    pos_transaction_edit: null,
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    set_row_checked: false,
    selected_row: null,
    is_discount: false,
    is_delivery: false,
    toggle_list: {
        position: 'right',
        open: false,
    },
    date_range: {
        startDate: null,
        endDate: null,
        // key: 'selection',
    },
    date_range_string: {
        startDate: '',
        endDate: '',
    },
    transaction_search: '',
};

const posReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPE.SET_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case TYPE.SET_MINI_LOADING: {
            return {
                ...state,
                mini_loading: action.payload,
            };
        }
        case TYPE.POS_GET_PRODUCTS:
            return {
                ...state,
                products: action.payload.products,
                multi_products: true,
            };
        case TYPE.POS_GET_CUSTOMERS:
            return {
                ...state,
                customer_options: action.payload,
            };
        case TYPE.POS_HANDLE_INPUT:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case TYPE.POS_DISCOUNT_TYPE:
            if (action.payload.name == 'discount_type') {
                return {
                    ...state,
                    discount_type: action.payload.value,
                    pos_discount_type: action.payload.value,
                    item_discount: action.payload.discount,
                };
            } else {
                // OVER-ALL COMPUTATION
                return {
                    ...state,
                    pos_discount_type: action.payload.value,
                    pos_discount: action.payload.discount,
                    pos_saving: 0,
                };
            }
        case TYPE.SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status,
            };
        case TYPE.POS_GET_SCAN:
            return {
                ...state,
                search: '',
                pos_item: action.payload,
                multi_products: false,
            };
        case TYPE.POS_SELECT_CUSTOMER:
            return {
                ...state,
                customer_option_value: action.payload,
                customer_id: action.payload.value,
            };
        case TYPE.POS_ADD_TO_LIST:
            return {
                ...state,
                pos_item_list: action.payload.pos_item_list,
                pos_transaction_id: action.payload.pos_transaction_id,
                pos_serial_no: action.payload.pos_serial_no,
                pos_sub_total: action.payload.pos_sub_total,
                pos_grand_total: action.payload.pos_grand_total,
            };
        case TYPE.POS_COMPUTE_TOTAL:
            return {
                ...state,
                pos_item: action.payload,
            };
        case TYPE.POS_COMPUTE_OVER_ALL_POS:
            return {
                ...state,
                pos_discount: action.payload.pos_discount,
                pos_delivery_fee: action.payload.pos_delivery_fee,
                pos_saving: action.payload.pos_saving,
                pos_sub_total: action.payload.pos_sub_total,
                pos_grand_total: action.payload.pos_grand_total,
            };
        case TYPE.POS_REMOVE_SCAN:
            return {
                ...state,
                pos_item: action.payload,
                search: '',
                qty: 1,
                item_discount: 0,
                discount_type: 'none',
                pos_transaction_edit: null,
            };
        case TYPE.POS_CLEAR:
            return {
                ...state,
                mini_loading: false,
                pos_item: [],
                search: '',
                pos_item_list: [],
                barcode: '',
                qty: 1,
                item_discount: 0,
                discount_type: 'none',
                discount_type_option: [
                    { value: 'none', label: 'None', disabled: false },
                    { value: 'percent', label: 'Percent', disabled: false },
                    { value: 'amount', label: 'Amount', disabled: false },
                    // { value: 'senior', label: 'Senior', disabled: false },
                    // { value: 'pwd', label: 'PWD', disabled: false },
                ],
                pos_discount_type: 'none',
                pos_discount: 0,
                pos_delivery_fee: 0,
                pos_saving: 0,
                pos_sub_total: 0.0,
                pos_grand_total: 0.0,
                pos_item_modal: false,
                multi_products: false,
                customer_modal: false,
                is_discount: false,
                is_delivery: false,
            };
        case TYPE.POS_UPDATE_QTY:
            return {
                ...state,
                qty: action.payload,
            };
        case TYPE.POS_UPDATE_LIST:
            return {
                ...state,
                pos_item_list: action.payload.pos_item_list,
                pos_sub_total: action.payload.pos_sub_total,
                pos_grand_total: action.payload.pos_grand_total,
            };
        case TYPE.POS_REMOVE_TRANSACTION:
            return {
                ...state,
                products: [],
                search: '',
                barcode: '',
                qty: 1,
                item_discount: 0,
                discount_type: 'none',
                pos_item: [],
                pos_item_list: [],
                pos_serial_no: '',
                pos_discount_type: 'none',
            };
        case TYPE.POS_SALES_LIST:
            return {
                ...state,
                pagination: action.payload.pagination,
                pos_sales: action.payload.pos_sales,
            };
        case TYPE.POS_SALES_DAILY:
            return {
                ...state,
                id: action.payload.id,
                pagination: action.payload.pagination,
                pos_daily: action.payload.pos_daily,
                pos_cutoff: action.payload.pos_cutoff,
                pos_cut_off_transactions:
                    action.payload.pos_cut_off_transactions,
                pos_user: action.payload.pos_user,
            };
        case TYPE.POS_LOADING:
            return {
                ...state,
                pos_loading: action.payload,
            };
        case TYPE.POS_TRANSACTION_VIEW:
            return {
                ...state,
                pos_item_list: action.payload.pos_item_list,
                pos_discount: action.payload.pos_discount,
                pos_delivery_fee: action.payload.pos_delivery_fee,
                pos_saving: action.payload.pos_saving,
                pos_sub_total: action.payload.pos_sub_total,
                pos_grand_total: action.payload.pos_grand_total,
                pos_transaction_id: action.payload.pos_transaction_id,
                pos_serial_no: action.payload.pos_serial_no,
                subs_setting: action.payload.subs_setting,
                pos_created_at: action.payload.pos_created_at,
                pos_transaction_edit: action.payload.pos_transaction_edit,
                pos_discount_type: action.payload.pos_discount_type,
                selected_row: action.payload.selected_row,
                pos_discount_amount: action.payload.pos_discount_amount,
                is_delivery: action.payload.is_delivery,
                is_discount: action.payload.is_discount,
            };
        case TYPE.POS_CANCEL_TRANSACTION_VIEW:
            return {
                ...state,
                pos_item: [],
                pos_item_list: [],
                pos_discount: 0,
                pos_delivery_fee: 0,
                pos_sub_total: 0.0,
                pos_grand_total: 0.0,
                pos_serial_no: '',
                pos_transaction_edit: null,
                selected_row: null,
            };
        case TYPE.POS_EDIT_TRANSACTION_ITEM:
            return {
                ...state,
                pos_item: action.payload.pos_item,
                qty: action.payload.qty,
                discount_type: action.payload.discount_type,
                item_discount: action.payload.item_discount,
                pos_transaction_edit: action.payload.pos_transaction_edit,
            };
        case TYPE.POS_TRANSACTION_EDIT:
            return {
                ...state,
                pos_transaction_edit: action.payload.pos_transaction_edit,
            };
        case TYPE.POS_ITEM_CLEAR:
            return {
                ...state,
                pos_item: [],
                barcode: '',
                qty: 1,
                item_discount: 0,
                discount_type: 'none',
                is_discount: false,
                is_delivery: false,
            };
        case TYPE.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction,
            };
        case TYPE.GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload,
            };
        case TYPE.SET_ROW_CHECKED:
            return {
                ...state,
                set_row_checked: true,
            };
        case TYPE.POS_ADD_DISCOUNT:
            if (action.payload) {
                return {
                    ...state,
                    is_discount: action.payload,
                    pos_saving: 0.0,
                };
            } else {
                return {
                    ...state,
                    is_discount: action.payload,
                    pos_discount_type: 'none',
                    pos_discount: 0,
                    pos_saving: 0,
                };
            }
        case TYPE.POS_ADD_DELIVERY:
            if (action.payload) {
                return {
                    ...state,
                    is_delivery: action.payload,
                };
            } else {
                return {
                    ...state,
                    is_delivery: action.payload,
                    pos_delivery_fee: 0,
                };
            }
        case TYPE.POS_TOGGLE_LIST:
            return {
                ...state,
                toggle_list: action.payload,
            };
        case TYPE.DATE_RANGE:
            return {
                ...state,
                date_range: action.payload.moment,
                date_range_string: action.payload.str,
            };
        case TYPE.PAGINATION:
            return {
                ...state,
                pagination: action.payload,
            };
        case TYPE.TRANSACTION_SEARCH:
            return {
                ...state,
                transaction_search: action.payload,
            };
        default:
            return state;
    }
};

export default posReducer;
