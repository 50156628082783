import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MiniSpinner } from '../layouts/Spinner';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
    attributeTemplatePost,
    handleInputChange,
    handleInputChangeAttr,
    getUnitMeasurement,
    handleSelectOptionProperty,
    handleAddAttribute,
    handleRemoveAttr,
    handleSelectUnit,
    handSelectInputType,
    handSelectAddOption,
    handSelectRemoveOption,
    handChangeInputOption,
    handSelectValidation,
    setModal,
    clearAttributeTemplate,
} from '../../redux/actions/attribute_template/attribute_template.actions';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions';

function AttributeTemplateCreateGlobal({
    attributes,
    attribute_template: {
        main_url,
        single_attribute_template,
        type_options,
        unit_options,
        unit_measurement,
        attributesList,
        validation_options,
        loading,
        create_modal,
    },
    handSelectInputType,
    handleSelectUnit,
    handleAddAttribute,
    attributeTemplatePost,
    handleInputChange,
    handleInputChangeAttr,
    getUnitMeasurement,
    handleSelectOptionProperty,
    handleRemoveAttr,
    handSelectAddOption,
    handSelectRemoveOption,
    handChangeInputOption,
    handSelectValidation,
    setModal,
    pageOrigin,
    clearAttributeTemplate,
    block_route,
    blockRoute,
}) {
    const default_attr = {
        id: '',
        label: '',
        inputy_type_value: null,
        unit_options: {},
        property_default: null,
        validation: {},
        unit_options_value: null,
        input_options: [{ label: '' }],
    };

    let history = useHistory();

    useEffect(() => {
        clearAttributeTemplate();
        getUnitMeasurement();
        blockRoute(false);
        // handleAddAttribute(attributesList, default_attr);
    }, []);

    useEffect(() => {
        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () =>
        window.history.pushState(null, null, window.location.pathname);

    if (single_attribute_template.success) return <Redirect to={main_url} />;
    let attr_list = attributesList;

    return (
        <Fragment>
            <BeforeUnloadComponent
                blockRoute={block_route}
                ignoreBeforeunloadDocument={true}
                modalComponentHandler={({
                    handleModalLeave,
                    handleModalCancel,
                }) => {
                    SwalFormWarning(
                        'Warning',
                        'Do you want to leave without saving',
                        () => handleModalLeave(),
                        () => handleModalCancel()
                    );
                }}
            >
                <form
                    onSubmit={(e) => attributeTemplatePost(e, pageOrigin)}
                    id="attribute-form"
                >
                    <section className="row">
                        <div className="col-sm-12 table-responsive">
                            <table className="table table-hover table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th className="text-center">
                                            Attribute Label Name{' '}
                                            <a
                                                href="#"
                                                className="tool-tip"
                                                data-tip="this is a tool tip"
                                            >
                                                <i className="la la-question-circle"></i>
                                            </a>
                                        </th>
                                        <th className="text-center">
                                            Measurement & Unit{' '}
                                            <a
                                                href="#"
                                                className="tool-tip"
                                                data-tip="this is a tool tip"
                                            >
                                                <i className="la la-question-circle"></i>
                                            </a>
                                        </th>
                                        <th className="text-center">
                                            Selection Type{' '}
                                            <a
                                                href="#"
                                                className="tool-tip"
                                                data-tip="this is a tool tip"
                                            >
                                                <i className="la la-question-circle"></i>
                                            </a>
                                        </th>
                                        <th className="text-center">
                                            Options{' '}
                                            <a
                                                href="#"
                                                className="tool-tip"
                                                data-tip="this is a tool tip"
                                            >
                                                <i className="la la-question-circle"></i>
                                            </a>
                                        </th>
                                        <th className="text-center"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attr_list.length !== null ? (
                                        attr_list.length > 0 ? (
                                            attributesList.map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td className="width-200 p-1 px-1">
                                                            <input
                                                                onChange={(e) =>
                                                                    handleInputChangeAttr(
                                                                        index,
                                                                        e,
                                                                        attributesList
                                                                    )
                                                                }
                                                                value={
                                                                    item.label
                                                                }
                                                                required
                                                                placeholder="Label Name"
                                                                type="text"
                                                                id="attribute_label"
                                                                name="attribute_label[]"
                                                                className="form-control"
                                                            />
                                                        </td>
                                                        <td className="min-width-md p-1 px-1 ">
                                                            <div class="row">
                                                                <div class="col-md-6 col-lg-6 col-sm-6 padding-l-r-2px">
                                                                    <Select
                                                                        styles={{
                                                                            menuPortal:
                                                                                (
                                                                                    base
                                                                                ) => ({
                                                                                    ...base,
                                                                                    zIndex: 9999,
                                                                                }),
                                                                        }}
                                                                        menuPortalTarget={
                                                                            document.body
                                                                        }
                                                                        className="Select-a"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleSelectOptionProperty(
                                                                                index,
                                                                                e,
                                                                                attributesList
                                                                            )
                                                                        }
                                                                        value={
                                                                            item.property_default
                                                                        }
                                                                        required
                                                                        name="property"
                                                                        isSearchable="true"
                                                                        options={
                                                                            unit_measurement
                                                                        }
                                                                        placeholder="Measurement"
                                                                    />{' '}
                                                                    <br />
                                                                </div>
                                                                <div class="col-md-6 col-lg-6 col-sm-6 padding-l-r-2px">
                                                                    <Select
                                                                        styles={{
                                                                            menuPortal:
                                                                                (
                                                                                    base
                                                                                ) => ({
                                                                                    ...base,
                                                                                    zIndex: 9999,
                                                                                }),
                                                                        }}
                                                                        menuPortalTarget={
                                                                            document.body
                                                                        }
                                                                        className=""
                                                                        name="unit"
                                                                        isSearchable="true"
                                                                        required
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleSelectUnit(
                                                                                index,
                                                                                e,
                                                                                attributesList
                                                                            )
                                                                        }
                                                                        value={
                                                                            item.unit_options_value
                                                                        }
                                                                        options={
                                                                            item.unit_options
                                                                        }
                                                                        placeholder="Unit"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="width-200 p-1 px-1">
                                                            <Select
                                                                styles={{
                                                                    menuPortal:
                                                                        (
                                                                            base
                                                                        ) => ({
                                                                            ...base,
                                                                            zIndex: 9999,
                                                                        }),
                                                                }}
                                                                menuPortalTarget={
                                                                    document.body
                                                                }
                                                                onChange={(e) =>
                                                                    handSelectInputType(
                                                                        index,
                                                                        e,
                                                                        attributesList
                                                                    )
                                                                }
                                                                value={
                                                                    item.inputy_type_value
                                                                }
                                                                isSearchable="false"
                                                                options={
                                                                    type_options
                                                                }
                                                                placeholder="Selection Type"
                                                            />
                                                        </td>
                                                        <td className="width-200 p-1 px-1">
                                                            {item.inputy_type_value !==
                                                            null ? (
                                                                item
                                                                    .inputy_type_value
                                                                    .value ===
                                                                    'Checkbox' ||
                                                                item
                                                                    .inputy_type_value
                                                                    .value ===
                                                                    'Radio' ||
                                                                item
                                                                    .inputy_type_value
                                                                    .value ===
                                                                    'Dropdown' ? (
                                                                    <div>
                                                                        {item.input_options.map(
                                                                            (
                                                                                input_opt,
                                                                                input_opt_index
                                                                            ) => (
                                                                                <div className="input-group mb-1">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        required
                                                                                        placeholder="Input Option"
                                                                                        aria-label="Input Option"
                                                                                        value={
                                                                                            input_opt.label
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handChangeInputOption(
                                                                                                index,
                                                                                                e,
                                                                                                attributesList,
                                                                                                input_opt_index
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <div className="input-group-append">
                                                                                        <button
                                                                                            className="btn btn-sm btn-danger"
                                                                                            type="button"
                                                                                            onClick={(
                                                                                                e
                                                                                            ) =>
                                                                                                handSelectRemoveOption(
                                                                                                    index,
                                                                                                    e,
                                                                                                    attributesList,
                                                                                                    input_opt_index
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="ft ft-x"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                        <span>
                                                                            <a
                                                                                className="btn btn-primary btn-sm text-white "
                                                                                onClick={(
                                                                                    e
                                                                                ) =>
                                                                                    handSelectAddOption(
                                                                                        index,
                                                                                        e,
                                                                                        attributesList
                                                                                    )
                                                                                }
                                                                            >
                                                                                Add
                                                                                Options
                                                                            </a>
                                                                        </span>
                                                                    </div>
                                                                ) : item.inputy_type_value !==
                                                                  null ? (
                                                                    item
                                                                        .inputy_type_value
                                                                        .value ===
                                                                    'Input Box' ? (
                                                                        <Select
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handSelectValidation(
                                                                                    index,
                                                                                    e,
                                                                                    attributesList
                                                                                )
                                                                            }
                                                                            isSearchable="false"
                                                                            options={
                                                                                validation_options
                                                                            }
                                                                            placeholder="Validation"
                                                                        />
                                                                    ) : (
                                                                        <span></span>
                                                                    )
                                                                ) : (
                                                                    <span></span>
                                                                )
                                                            ) : (
                                                                <span>
                                                                    <center></center>
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td className="max-wdth-100">
                                                            <center>
                                                                <a
                                                                    className="btn btn-sm btn-danger align-middle text-white"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        handleRemoveAttr(
                                                                            index,
                                                                            attributesList
                                                                        )
                                                                    }
                                                                >
                                                                    {' '}
                                                                    <i className="ft ft-trash"></i>
                                                                </a>
                                                            </center>
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td
                                                    className="text-center"
                                                    colSpan="10"
                                                >
                                                    <span>
                                                        You have no attribute,
                                                        please press Add.
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    ) : (
                                        <tr>
                                            <td
                                                className="text-center"
                                                colSpan="10"
                                            >
                                                <span>
                                                    You have no attribute,
                                                    please press Add.
                                                </span>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan="10">
                                            <a
                                                className="btn btn-sm btn-primary text-white"
                                                onClick={(e) =>
                                                    handleAddAttribute(
                                                        attributesList,
                                                        default_attr
                                                    )
                                                }
                                            >
                                                Add Attributes
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </section>

                    <div className="row justify-content-end">
                        <div className="mr-2">
                            <div className="form-group">
                                {/* <a type="button" className="btn btn-sm btn-danger" href="/products/attribute-template">Cancel</a>{" "} */}
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-save"
                                >
                                    Save Attribute
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                <Modal
                    dialogClassName="modal-dialog modal-sm"
                    show={create_modal}
                    size="sm"
                    onHide={() => {
                        setModal('create_modal', null, false);
                    }}
                    animation={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Attribute Template Name</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                    Give your group of Attributes a Template
                                    Name to access them easier later (e.g
                                    Furniture Attributes, Phone Attributes)
                                </p>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="label-control">
                                        Template Name:{' '}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        onChange={handleInputChange}
                                        value={
                                            single_attribute_template.template_name
                                        }
                                        required
                                        type="text"
                                        id="name"
                                        name="template_name"
                                        className="form-control"
                                        placeholder="Template Name"
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger  btn-sm"
                            onClick={() => {
                                setModal('create_modal', null, false);
                            }}
                        >
                            Close{' '}
                        </Button>
                        <button
                            type="submit"
                            className="btn btn-sm btn-save btn-primary"
                            form="attribute-form"
                        >
                            Save Attribute
                        </button>
                    </Modal.Footer>
                </Modal>
            </BeforeUnloadComponent>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    attribute_template: state.attribute_template,
    attributes: state.attribute_template.attributes,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    attributeTemplatePost,
    handleInputChange,
    handleInputChangeAttr,
    getUnitMeasurement,
    handleSelectOptionProperty,
    handleAddAttribute,
    handleRemoveAttr,
    handleSelectUnit,
    handSelectInputType,
    handSelectAddOption,
    handSelectRemoveOption,
    handChangeInputOption,
    handSelectValidation,
    setModal,
    clearAttributeTemplate,
    blockRoute,
})(AttributeTemplateCreateGlobal);
