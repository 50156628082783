import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Spinner, { MiniSpinner } from '../../layouts/Spinner';
import { Show, useAccess } from 'react-redux-permission';
import { numberFormat, onlyNumber } from '../../../Utils/Common';
import { getProducts } from '../../../redux/actions/product/product.actions';
import {
    setModal,
    handleInputChange,
    handleUpdateInputChange,
    searchSubmit,
    selectCustomer,
    discountType,
    addToList,
    removeScan,
    searchProduct,
    updateQty,
    updateTransactionItemQty,
    posEditTransactionItem,
    toggleDrawer,
    posWarningConfirmation,
    posCloseTransaction,
    posDaily,
    posTransactionView,
    cancelPosTransactionView,
    posTransactionChecker,
    posUpdateTransactionItem,
    posUpdateTransaction,
    addDiscount,
    addDelivery,
} from '../../../redux/actions/pos/pos.actions';
import { getCustomers } from '../../../redux/actions/quotation/quotation.actions';
import { formattedDate } from '../../../Utils/Common';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
    makeStyles,
    ThemeProvider,
} from '@material-ui/core/styles';
import POSItemModal from '../modals/POS.item.modal';
import moment from 'moment';
import POSDailytransaction from './POS.dailytransaction';
import Drawer from '@material-ui/core/Drawer';
import RestoreIcon from '@material-ui/icons/Restore';
import { handleHover } from '../../../redux/actions/helper/helper.actions';

function POSBarcode({
    pos,
    handleSearchInputMUI,
    handleInputChange,
    handleUpdateInputChange,
    searchSubmit,
    getCustomers,
    selectCustomer,
    discountType,
    setModal,
    addToList,
    removeScan,
    searchProduct,
    updateQty,
    updateTransactionItemQty,
    posEditTransactionItem,
    posWarningConfirmation,
    posCloseTransaction,
    posDaily,
    posTransactionView,
    cancelPosTransactionView,
    posTransactionChecker,
    posUpdateTransactionItem,
    posUpdateTransaction,
    hoverIndex,
    handleHover,
    addDiscount,
    toggleDrawer,
    addDelivery,
}) {
    const { pos_item } = pos;

    // const [toggle, setToggle] = useState({ position: 'right', open: false});

    useEffect(() => {
        const executeSequentially = async () => {
            await posTransactionChecker();
            await posDaily();
        };
        executeSequentially();
    }, []);

    const GlobalCss = withStyles({
        // @global is handled by jss-plugin-global.
        '@global': {
            // You should target [className*="MuiButton-root"] instead if you nest themes.
            '.MuiDrawer-paperAnchorRight': {
                right: 0,
                width: '570px',
                height: '750px',
                bottom: 0,
                top: '50px',
                // marginTop: 'auto',
                marginBottom: '7rem',
            },
        },
    })(() => null);

    const posDailyTrans = () => (
        <div role="presentation" onKeyDown={() => toggleDrawer(false)}>
            <div className="toggle-list-header">
                <i
                    onClick={() => toggleDrawer(false)}
                    className="ft-arrow-right toggle-list-icon"
                ></i>
                <span>Transaction History</span>
            </div>
            <POSDailytransaction />
        </div>
    );

    const [isPaying, setIsPaying] = useState(false);

    const handleCloseTransaction = () => {
        setIsPaying(true);
        posCloseTransaction(() => setIsPaying(false)); // assuming posCloseTransaction takes a callback that it calls when it finishes
    };

    // const toggleDrawer = (open) => setToggle({ ...toggle, open: open});

    // console.log('pos', pos);
    return (
        <>
            <div onClick={() => toggleDrawer(true)} className="pos-btn-toggle">
                <RestoreIcon style={{ fontSize: '3rem', fontWeight: '600' }} />
            </div>
            <GlobalCss />
            <Drawer
                anchor={pos.toggle_list.position}
                open={pos.toggle_list.open}
                onClose={() => toggleDrawer(false)}
            >
                {posDailyTrans()}
            </Drawer>
            <div className="card">
                <ReactTooltip effect="solid" event="click" />
                <div className="card-content collapse show">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="mb-2">
                                            <h4 className="card-title">
                                                POS{' '}
                                                <a
                                                    href="#"
                                                    className="tool-tip"
                                                    data-tip="this is a tool tip"
                                                >
                                                    <i className="la la-question-circle"></i>
                                                </a>
                                            </h4>
                                            <div className="heading-elements">
                                                <ul className="list-inline mb-0"></ul>
                                            </div>
                                        </div>
                                        <div className="my-2 row pr-1">
                                            <div className="col-lg-4 col-12 mb-2">
                                                <div>
                                                    Date:{' '}
                                                    {pos.pos_daily
                                                        ? moment(
                                                              pos.pos_daily.date
                                                          ).format(
                                                              'MMMM D, YYYY'
                                                          )
                                                        : ''}
                                                </div>
                                                <div>
                                                    Location: &nbsp;{' '}
                                                    {pos.pos_cutoff &&
                                                        pos.pos_cutoff.location}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-12">
                                                <div className="pos-details">
                                                    Opening Time:{' '}
                                                    {pos.pos_daily.start &&
                                                        moment(
                                                            pos.pos_daily.start,
                                                            'h:mm'
                                                        ).format('h:mm A')}
                                                </div>
                                                <div className="pos-details">
                                                    Closing Time:{' '}
                                                    {pos.pos_daily.end &&
                                                        moment(
                                                            pos.pos_daily.end,
                                                            'h:mm'
                                                        ).format('h:mm A')}
                                                </div>
                                                <div className="pos-details">
                                                    Beginning Balance: &nbsp;{' '}
                                                    {pos.pos_cutoff &&
                                                        pos.pos_cutoff
                                                            .beginning_balance}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-12">
                                                <div className="pos-details">
                                                    Total Sales: &nbsp;{' '}
                                                    {pos.pos_daily.total_sales
                                                        ? numberFormat(
                                                              pos.pos_daily
                                                                  .total_sales
                                                          )
                                                        : '0.00'}
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="pos-details">
                                                    Cashier: &nbsp;{' '}
                                                    {pos.pos_user &&
                                                        pos.pos_user.admin_name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="row">
                                    <div className="col-12 mb-2 pr-2">
                                        <div className="row pos-create-bg">
                                            <div className="col-8 mb-2 ml-0">
                                                <div className="row">
                                                    <label className="col-md-4 col-sm-12 pl-0">
                                                        SKU/Barcode:{' '}
                                                    </label>
                                                    <div className="col-md-7 col-10 px-0">
                                                        <form
                                                            onSubmit={(e) =>
                                                                searchSubmit(
                                                                    e,
                                                                    'btn'
                                                                )
                                                            }
                                                        >
                                                            <div className="input-group mb-0">
                                                                <input
                                                                    type="text"
                                                                    id="search"
                                                                    value={
                                                                        pos.search
                                                                    }
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                    name="search"
                                                                    className="form-control f-regular"
                                                                    placeholder="Search"
                                                                    required
                                                                    disabled={
                                                                        pos.pos_transaction_edit ===
                                                                        'transaction'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                <div className="input-group-append">
                                                                    <button
                                                                        className="btn btn-sm btn-primary btn-z-index search-btn"
                                                                        type="submit"
                                                                    >
                                                                        {pos.pos_loading ==
                                                                            'pos-search' &&
                                                                        pos.multi_products ==
                                                                            false ? (
                                                                            <span
                                                                                className="spinner-border spinner-border-sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            ></span>
                                                                        ) : (
                                                                            <i className="ft ft-search"></i>
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="col-1 pr-0">
                                                        <div className="float-right">
                                                            <div className="input-group-prepend">
                                                                <span
                                                                    href="#"
                                                                    className="tool-tip text-primary"
                                                                    data-tip="this is a tool tip"
                                                                >
                                                                    <i className="la la-question-circle"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4"></div>

                                            {pos.pos_item.length > 0 ? (
                                                <div className="col-12 card p-1">
                                                    <div className="card-header p-0">
                                                        <div className="d-flex justify-content-end">
                                                            <div>
                                                                {pos.pos_loading ==
                                                                'pos-scan' ? (
                                                                    ''
                                                                ) : (
                                                                    <i
                                                                        onClick={() =>
                                                                            removeScan()
                                                                        }
                                                                        className="ft ft-x ft-tran-icon font-weight-bold pos-close"
                                                                    ></i>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card-body row pt-0">
                                                        {pos.pos_loading ==
                                                        'pos-scan' ? (
                                                            <div className="mx-auto my-4">
                                                                <MiniSpinner />
                                                            </div>
                                                        ) : (
                                                            <div className="row my-2">
                                                                <div className="col-lg-6 col-6">
                                                                    <div className="row justify-content-between">
                                                                        <div className="col-6">
                                                                            <img
                                                                                src={
                                                                                    pos_item[0]
                                                                                        .product_image
                                                                                }
                                                                                className="img-fluid pos-img mt-0 pt-0"
                                                                                alt="img"
                                                                            />
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {pos_item[0]
                                                                                .stockWarning && (
                                                                                <span
                                                                                    className={`lbl-status lbl-lg lbl-pos-warning`}
                                                                                >
                                                                                    LOW
                                                                                    THRESHOLD
                                                                                </span>
                                                                            )}
                                                                            <div className="pos-strong-text pt-1">
                                                                                {
                                                                                    pos_item[0]
                                                                                        .product_name
                                                                                }
                                                                            </div>
                                                                            <div className="pos-description">
                                                                                SKU:{' '}
                                                                                {
                                                                                    pos_item[0]
                                                                                        .sku
                                                                                }
                                                                            </div>
                                                                            <div className="pos-description">
                                                                                Barcode:{' '}
                                                                                {
                                                                                    pos_item[0]
                                                                                        .barcode
                                                                                }
                                                                            </div>
                                                                            <div className="pos-description">
                                                                                Brand:{' '}
                                                                                {
                                                                                    pos_item[0]
                                                                                        .brand_name
                                                                                }
                                                                            </div>
                                                                            <div className="pos-description mb-2">
                                                                                SRP:
                                                                                P{' '}
                                                                                {numberFormat(
                                                                                    pos_item[0]
                                                                                        .srp
                                                                                )}
                                                                            </div>
                                                                            {/* <div className="col-6 mb-0">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="dicount"><small>Discount</small></label>
                                                                                        {
                                                                                            pos.discount_type != 'none' ?  <input onChange={handleInputChange} onKeyPress={e => onlyNumber(e)} name="item_discount" value={pos.item_discount} id="item_discount" type="number" className="form-control"/>
                                                                                            : <div className="form-control bg-disabled"></div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6 mb-0">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="dicount_type"><small>Discount Type</small></label>
                                                                                        <select name="discount_type" onChange={discountType} className="custom-select f-regular">
                                                                                            { pos.discount_type_option.map(option => (<option value={option.value} selected={option.value == pos.discount_type ? 'selected' : ''}>{option.label}</option>)) }
                                                                                        </select>
                                                                                    </div>
                                                                                </div> */}
                                                                            <div className="pos-strong-text">
                                                                                TOTAL:{' '}
                                                                                {numberFormat(
                                                                                    pos_item[0]
                                                                                        ._total_item_price
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-3 text-center">
                                                                    <div className="input-group pos-c-grp">
                                                                        {pos.pos_transaction_edit ===
                                                                            'transaction' ||
                                                                        pos.pos_transaction_edit ===
                                                                            'update_item' ? (
                                                                            <>
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            updateTransactionItemQty(
                                                                                                'dec'
                                                                                            )
                                                                                        }
                                                                                        className="input-group-text p-0 pos-btn-grey"
                                                                                    >
                                                                                        <i className="ft ft-minus pos-qty-icon"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input
                                                                                    onChange={
                                                                                        handleUpdateInputChange
                                                                                    }
                                                                                    onKeyPress={(
                                                                                        e
                                                                                    ) =>
                                                                                        onlyNumber(
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                    name="qty"
                                                                                    value={
                                                                                        pos.qty
                                                                                    }
                                                                                    type="number"
                                                                                    className="form-control form-control-lg pos-c-qty f-regular"
                                                                                    min="0"
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            updateQty(
                                                                                                'dec'
                                                                                            )
                                                                                        }
                                                                                        className="input-group-text p-0 pos-btn-grey"
                                                                                    >
                                                                                        <i className="ft ft-minus pos-qty-icon"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <input
                                                                                    onChange={
                                                                                        handleInputChange
                                                                                    }
                                                                                    onKeyPress={(
                                                                                        e
                                                                                    ) =>
                                                                                        onlyNumber(
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                    name="qty"
                                                                                    value={
                                                                                        pos.qty
                                                                                    }
                                                                                    type="number"
                                                                                    className="form-control form-control-lg pos-c-qty f-regular"
                                                                                    min="1"
                                                                                />
                                                                                <div className="input-group-append">
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            updateQty(
                                                                                                'inc'
                                                                                            )
                                                                                        }
                                                                                        className="input-group-text p-0 pos-btn-grey"
                                                                                    >
                                                                                        <i className="ft ft-plus pos-qty-icon"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-3">
                                                                    <div className="pos-btn-add">
                                                                        {pos.pos_transaction_edit ===
                                                                            'transaction' ||
                                                                        pos.pos_transaction_edit ===
                                                                            'update_item' ||
                                                                        pos.pos_transaction_edit ===
                                                                            'item_list' ||
                                                                        pos.pos_transaction_edit ===
                                                                            '' ? (
                                                                            <button
                                                                                onClick={() =>
                                                                                    posUpdateTransactionItem(
                                                                                        pos.pos_transaction_id
                                                                                    )
                                                                                }
                                                                                type="button"
                                                                                className="btn btn-info btn-lg mr-0 btn-add"
                                                                                disabled={
                                                                                    pos.qty ===
                                                                                    pos
                                                                                        .pos_item[0]
                                                                                        .order_qty
                                                                                }
                                                                            >
                                                                                Update
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                onClick={
                                                                                    addToList
                                                                                }
                                                                                type="button"
                                                                                className="btn btn-primary btn-lg mr-0 btn-add"
                                                                            >
                                                                                Add
                                                                            </button>
                                                                        )}

                                                                        <br />
                                                                        {/* <i className="la la-sort-desc inv-icon-down"></i> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="col-12 card mb-0">
                                                    <div className="card-body row pos-blank-card">
                                                        {/* THIS IS A EMPTY CARD */}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div>{/* <POSDailytransaction/> */}</div>
                            </div>

                            <div className="col-lg-5 col-md-12">
                                <div className="row justify-content-center">
                                    <div className="col-md-12 col-12">
                                        <div className="row pos-create-bg">
                                            <div className="col-12 px-0 pos-c-top-header">
                                                <div className="float-left">
                                                    <div>
                                                        Transaction No.{' '}
                                                        {pos.pos_serial_no}
                                                    </div>
                                                </div>
                                                <div className="float-right">
                                                    <button className="btn pos-trans-btn p-0">
                                                        <i className="fas fa-window-minimize pos-trans-icon pos-f-weight-400"></i>
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            cancelPosTransactionView(
                                                                'pos-edit'
                                                            )
                                                        }
                                                        className="btn pos-trans-btn ml-1 p-0"
                                                    >
                                                        <i className="ft ft-x pos-trans-icon pos-f-weight-600"></i>
                                                    </button>{' '}
                                                    :
                                                    {/* {
                                                        pos.pos_transaction_edit === 'transaction' ?  <button onClick={() => cancelPosTransactionView('pos-edit')} className="btn pos-trans-btn ml-1 p-0"><i className="ft ft-x pos-trans-icon pos-f-weight-600"></i></button> :
                                                        <button onClick={() => posWarningConfirmation('cancel-transaction')} className="btn pos-trans-btn ml-1 p-0"><i className="ft ft-x pos-trans-icon pos-f-weight-600"></i></button>
                                                    } */}
                                                </div>
                                            </div>

                                            {
                                                <div className="col-12 card mt-2 mb-0">
                                                    {pos.pos_loading ==
                                                    'transaction-view' ? (
                                                        <div className="mx-auto my-4">
                                                            <MiniSpinner />
                                                        </div>
                                                    ) : pos.pos_item_list &&
                                                      pos.pos_item_list.length >
                                                          0 ? (
                                                        <div className="card-body row rounded-lg pos-trans-view">
                                                            <div className="ml-auto">
                                                                {
                                                                    // pos.pos_transaction_edit === 'transaction' ? <i onClick={() => cancelPosTransactionView('pos-edit')} className="ft ft-x ft-tran-icon font-weight-bold"></i> :
                                                                    // <i onClick={() => posWarningConfirmation('cancel-transaction')} className="ft ft-x ft-tran-icon font-weight-bold"></i>
                                                                }
                                                            </div>
                                                            <table className="table pos-table pos-table-r mt-1 pos-details">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Description
                                                                        </th>
                                                                        <th>
                                                                            Price
                                                                        </th>
                                                                        <th className="text-center">
                                                                            Qty
                                                                        </th>
                                                                        <th className="text-right">
                                                                            Amount
                                                                        </th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {pos.pos_item_list.map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => (
                                                                            <tr
                                                                                onMouseEnter={() =>
                                                                                    handleHover(
                                                                                        'in',
                                                                                        index
                                                                                    )
                                                                                }
                                                                                onMouseLeave={() =>
                                                                                    handleHover(
                                                                                        'out',
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                <td>
                                                                                    {item.product_name.slice(
                                                                                        0,
                                                                                        15
                                                                                    ) +
                                                                                        (item
                                                                                            .product_name
                                                                                            .length >
                                                                                        15
                                                                                            ? '...'
                                                                                            : '')}
                                                                                </td>
                                                                                <td>
                                                                                    {numberFormat(
                                                                                        item.srp
                                                                                    )}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {
                                                                                        item.order_qty
                                                                                    }
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {numberFormat(
                                                                                        item.amount
                                                                                    )}
                                                                                </td>
                                                                                <td
                                                                                    className="text-right"
                                                                                    width="50px"
                                                                                >
                                                                                    <>
                                                                                        <Show when="inv.sales.pos.edit.item">
                                                                                            <i
                                                                                                onClick={() =>
                                                                                                    posEditTransactionItem(
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                                className="ft ft-edit pos-c-edit text-secondary pr-1"
                                                                                            ></i>
                                                                                        </Show>
                                                                                        {pos.pos_transaction_edit ===
                                                                                            'transaction' ||
                                                                                        pos.pos_transaction_edit ===
                                                                                            'update_item' ? (
                                                                                            <>

                                                                                            </>
                                                                                        ) : (
                                                                                            <Show when="inv.sales.pos.delete.item">
                                                                                                <i
                                                                                                    onClick={() =>
                                                                                                        posWarningConfirmation(
                                                                                                            'remove-item',
                                                                                                            item
                                                                                                        )
                                                                                                    }
                                                                                                    className="ft ft-trash pos-c-edit text-danger"
                                                                                                ></i>
                                                                                            </Show>
                                                                                        )}
                                                                                    </>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                            {/* <div className="col-lg-6"></div> */}

                                                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-4 mt-5 pos-details mb-4">
                                                                <div className="row">
                                                                    <div className="col-6 font-weight-bold">
                                                                        {' '}
                                                                        Sub
                                                                        Total:{' '}
                                                                    </div>{' '}
                                                                    <div className="col-6 text-right font-weight-bold">
                                                                        P{' '}
                                                                        {numberFormat(
                                                                            pos.pos_sub_total
                                                                        )}{' '}
                                                                    </div>
                                                                    <Show when="inv.sales.pos.delivery_fee">
                                                                        <div className="col-6 mt-1">
                                                                            {!pos.is_delivery ? (
                                                                                <span
                                                                                    onClick={() =>
                                                                                        addDelivery(
                                                                                            true
                                                                                        )
                                                                                    }
                                                                                    className="text-primary font-weight-bold txt-underline"
                                                                                >
                                                                                    Add
                                                                                    Delivery
                                                                                    Fee
                                                                                </span>
                                                                            ) : (
                                                                                <span
                                                                                    onClick={() =>
                                                                                        addDelivery(
                                                                                            false
                                                                                        )
                                                                                    }
                                                                                    className="text-primary font-weight-bold txt-underline"
                                                                                >
                                                                                    No
                                                                                    Delivery
                                                                                    Fee
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-6 mt-1"></div>
                                                                        {pos.is_delivery ? (
                                                                            <>
                                                                                <div className="col-6 font-weight-bold font-gray mb-1 align-self-center">
                                                                                    {' '}
                                                                                    Delivery
                                                                                    Fee:
                                                                                </div>{' '}
                                                                                <div className="col-6 text-right font-weight-bold font-gray mb-1">
                                                                                    <div className="input-group">
                                                                                        <input
                                                                                            onChange={
                                                                                                handleInputChange
                                                                                            }
                                                                                            name="pos_delivery_fee"
                                                                                            value={
                                                                                                pos.pos_delivery_fee
                                                                                            }
                                                                                            id="pos_delivery_fee"
                                                                                            className="form-control text-right f-regular"
                                                                                            type="number"
                                                                                            min="0"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="col-6 font-weight-bold">
                                                                                    {' '}
                                                                                    &nbsp;
                                                                                </div>{' '}
                                                                                <div className="col-6 text-right font-weight-bold">
                                                                                    &nbsp;{' '}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Show>
                                                                    <Show when="inv.sales.pos.discount">
                                                                        <div className="col-6 mt-1">
                                                                            {!pos.is_discount ? (
                                                                                <span
                                                                                    onClick={() =>
                                                                                        addDiscount(
                                                                                            true
                                                                                        )
                                                                                    }
                                                                                    className="text-primary font-weight-bold txt-underline"
                                                                                >
                                                                                    Add
                                                                                    Discount
                                                                                </span>
                                                                            ) : (
                                                                                <span
                                                                                    onClick={() =>
                                                                                        addDiscount(
                                                                                            false
                                                                                        )
                                                                                    }
                                                                                    className="text-primary font-weight-bold txt-underline"
                                                                                >
                                                                                    No
                                                                                    Discount
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-6 mt-1"></div>
                                                                        {pos.is_discount ||
                                                                        pos.pos_discount_type !==
                                                                            'none' ? (
                                                                            <>
                                                                                <div className="col-6 my-1">
                                                                                    <div>
                                                                                        Type:
                                                                                    </div>
                                                                                    <select
                                                                                        name="discount_type"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            discountType(
                                                                                                e,
                                                                                                'over-all'
                                                                                            )
                                                                                        }
                                                                                        className="custom-select f-regular"
                                                                                    >
                                                                                        {pos.discount_type_option.map(
                                                                                            (
                                                                                                option
                                                                                            ) => (
                                                                                                <option
                                                                                                    value={
                                                                                                        option.value
                                                                                                    }
                                                                                                    selected={
                                                                                                        option.value ==
                                                                                                        pos.pos_discount_type
                                                                                                            ? 'selected'
                                                                                                            : ''
                                                                                                    }
                                                                                                    disabled={
                                                                                                        option.disabled
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        option.label
                                                                                                    }
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </select>
                                                                                </div>
                                                                                <div className="col-6 my-1">
                                                                                    <div className="text-right">
                                                                                        Discount:
                                                                                    </div>
                                                                                    {/* <input onChange={handleInputChange} onKeyPress={e => onlyNumber(e)} name="pos_discount" value={pos.pos_discount} id="pos_discount" type="number" className="form-control" disabled={pos.pos_discount_type == 'none' || pos.pos_discount_type == 'senior_pwd' ? true : false}/> */}

                                                                                    {/* <div className="form-control bg-disabled"> */}
                                                                                    {/* {pos.pos_discount ===
                                                                                        0
                                                                                            ? pos.pos_discount
                                                                                            : pos.pos_discount +
                                                                                              '%'} */}

                                                                                    <span className="w-20 table-data__discount">
                                                                                        {(() => {
                                                                                            switch (
                                                                                                pos.discount_type
                                                                                            ) {
                                                                                                case 'percent':
                                                                                                    return (
                                                                                                        <div className="input-group">
                                                                                                            <input
                                                                                                                onChange={
                                                                                                                    handleInputChange
                                                                                                                }
                                                                                                                name="pos_discount"
                                                                                                                value={
                                                                                                                    pos.pos_discount
                                                                                                                }
                                                                                                                id="pos_discount"
                                                                                                                className="form-control text-right f-regular"
                                                                                                                type="number"
                                                                                                                min="0"
                                                                                                            />
                                                                                                            <div className="input-group-append">
                                                                                                                <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                    %
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    );
                                                                                                case 'amount':
                                                                                                    return (
                                                                                                        <input
                                                                                                            onChange={
                                                                                                                handleInputChange
                                                                                                            }
                                                                                                            name="pos_discount"
                                                                                                            value={
                                                                                                                pos.pos_discount
                                                                                                            }
                                                                                                            id="pos_discount"
                                                                                                            className="form-control text-right f-regular"
                                                                                                            type="number"
                                                                                                            min="0"
                                                                                                        />
                                                                                                    );
                                                                                                default: // 0 disabled
                                                                                                    return (
                                                                                                        <input
                                                                                                            value={
                                                                                                                pos.pos_discount
                                                                                                            }
                                                                                                            disabled
                                                                                                            name="pos_discount"
                                                                                                            className="form-control text-right f-regular"
                                                                                                            type="number"
                                                                                                        />
                                                                                                    );
                                                                                            }
                                                                                        })()}
                                                                                    </span>
                                                                                    {/* </div> */}
                                                                                </div>
                                                                                <div className="col-6 font-weight-bold font-gray mb-1">
                                                                                    {' '}
                                                                                    Your
                                                                                    Saving:
                                                                                </div>{' '}
                                                                                <div className="col-6 text-right font-weight-bold font-gray mb-1">
                                                                                    {numberFormat(
                                                                                        pos.pos_saving
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="col-6 font-weight-bold">
                                                                                    {' '}
                                                                                    &nbsp;
                                                                                </div>{' '}
                                                                                <div className="col-6 text-right font-weight-bold">
                                                                                    &nbsp;{' '}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Show>
                                                                    <div className="col-6 font-weight-bold">
                                                                        {' '}
                                                                        Tax:{' '}
                                                                    </div>{' '}
                                                                    <div className="col-6 text-right font-weight-bold">
                                                                        0.00{' '}
                                                                    </div>
                                                                    <div className="col-6 font-weight-bold mt-1">
                                                                        {' '}
                                                                        Total:
                                                                    </div>{' '}
                                                                    <div className="col-6 text-right mt-1 font-weight-bold">
                                                                        {' '}
                                                                        P{' '}
                                                                        {numberFormat(
                                                                            pos.pos_grand_total
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="float-right">
                                                                    {pos.pos_transaction_edit ===
                                                                    'transaction' ? (
                                                                        <>
                                                                            <button
                                                                                onClick={() =>
                                                                                    posWarningConfirmation(
                                                                                        'cancel-transaction'
                                                                                    )
                                                                                }
                                                                                type="button"
                                                                                className="btn btn-danger mx-2"
                                                                                disabled={
                                                                                    isPaying
                                                                                }
                                                                            >
                                                                                {' '}
                                                                                CANCEL{' '}
                                                                            </button>
                                                                            {/* <button onClick={handleCloseTransaction} type="button" className="btn btn-primary btn-trans"> PAY </button> */}
                                                                            {/* <button onClick={() => posUpdateTransaction()} type="button" className="btn btn-info btn-trans">UPDATE</button> */}
                                                                        </>
                                                                    ) : pos.pos_transaction_edit ===
                                                                      'update_item' ? (
                                                                        <>
                                                                            <button
                                                                                onClick={
                                                                                    handleCloseTransaction
                                                                                }
                                                                                type="button"
                                                                                className="btn btn-primary btn-trans ml-1 py-2 px-3"
                                                                            >
                                                                                {' '}
                                                                                UPDATE{' '}
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {/* <button onClick={() => posWarningConfirmation('cancel-transaction')} type="button" className="btn btn-danger py-2 px-2" disabled={isPaying}> CANCEL </button>
                                                                             */}
                                                                            <button
                                                                                onClick={
                                                                                    handleCloseTransaction
                                                                                }
                                                                                type="button"
                                                                                className="btn btn-primary btn-trans ml-1 py-2 px-3"
                                                                            >
                                                                                {' '}
                                                                                PAY{' '}
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="card-body row pos-blank-card">
                                                            <div className="mx-auto"></div>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <POSItemModal />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    pos: state.pos,
    hoverIndex: state.helper.hoverIndex,
});

export default connect(mapStateToProps, {
    setModal,
    getProducts,
    handleInputChange,
    handleUpdateInputChange,
    searchSubmit,
    getCustomers,
    selectCustomer,
    discountType,
    setModal,
    addToList,
    removeScan,
    searchProduct,
    updateQty,
    updateTransactionItemQty,
    posEditTransactionItem,
    posWarningConfirmation,
    posCloseTransaction,
    posDaily,
    posTransactionView,
    cancelPosTransactionView,
    posTransactionChecker,
    posUpdateTransactionItem,
    posUpdateTransaction,
    handleHover,
    addDiscount,
    toggleDrawer,
    addDelivery,
})(POSBarcode);
