import { getNumRows } from '../../../Utils/Common.js';
import * as TYPES from '../../types.js';
import {
    ToastSuccess,
    ToastDanger,
    ToastWarning,
    SwalWarningHtml,
    SwalWarning,
} from '../../../Services/_toast.service';
import { SwalWarningStock } from '../../../Services/_swal.service';
import $ from 'jquery';
import { blockRoute, setMUIPage } from '../helper/helper.actions';
import { StockTransferService } from './_service.stock_transfer.js';
import { PurchaseOrderService } from '../purchase_order/_service.purchase_order.js';

const spinLG =
    '<span class="spinner-border" role="status" aria-hidden="true"></span>';

// handle Inputs
export const handleInputChange = (e) => async (dispatch) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({
        type: TYPES.STOCK_TRANSFER_INPUT_CHANGE,
        payload: { name: e.target.name, value: e.target.value },
    });
};

// handle search
export const handleSearchInput = (e) => async (dispatch) => {
    dispatch({ type: TYPES.SEARCH_INPUT, payload: e.target.value });
};

// loading
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: TYPES.SET_LOADING, payload: status });
export const setMiniLoading = (status) => async (dispatch) =>
    dispatch({ type: TYPES.SET_MINI_LOADING, payload: status });

// clear data
export const clearStockTransfer = () => async (dispatch) =>
    dispatch({ type: TYPES.CLEAR_STOCK_TRANSFER });

// set page
export const setPage =
    (page, status = true) =>
    async (dispatch) => {
        dispatch({ type: TYPES.SET_PAGE, payload: { page, status } });
    };

// fetch stock_transfers
export const getStockTransfers =
    (pageNumber, rows_per_page = null) =>
    async (dispatch, getState) => {
        dispatch(setLoading(true));
        let { search, pagination, sort_order_name, sort_order_direction } =
            getState().stock_transfer;
        let rows_per_page_val =
            rows_per_page != null
                ? rows_per_page
                : getNumRows('stock_transfer');

        let searchPostParams = {
            // search: search,
            // activePage: pagination.activePage,
            // page: pageNumber,
            // rows_per_page: rows_per_page_val,
            // sort_order_name: sort_order_name,
            // sort_order_direction: sort_order_direction
            search,
            options: {
                page: pageNumber ? pageNumber : pagination.activePage,
                limit: rows_per_page_val ? rows_per_page_val : 10,
                sort_by: sort_order_name,
                sort_order: sort_order_direction,
            },
        };

        StockTransferService.getStockTransfers(searchPostParams)
            .then((res) => {
                dispatch({
                    type: TYPES.GET_STOCK_TRANSFERS,
                    payload: {
                        stock_transfers: res.data.docs,
                        pagination: {
                            //   totalCount: res.data.stock_transfers.total,
                            //   activePage: res.data.stock_transfers.current_page,
                            //   itemsCountPerPage: res.data.stock_transfers.per_page,
                            //   totalItemsCount: res.data.stock_transfers.total,
                            totalCount: res.data.total_docs,
                            activePage: res.data.page,
                            itemsCountPerPage: res.data.limit,
                            totalItemsCount: res.data.total_docs,
                        },
                    },
                });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                // if (error.data.errors.length > 0) {
                //   error.data.errors.map((err) => ToastDanger(err));
                // } else {
                //   ToastDanger("Network error, please reload the page.");
                // }
                dispatch(setLoading(false));

                // $(".btn-save").removeAttr("disabled").html("Save");
                console.log(error);
            });
    };

// fetch single stock_transfers
export const getSingleStockTransfer =
    (id, status = null) =>
    async (dispatch) => {
        if (!status) {
            dispatch(setLoading(true));
        }

        StockTransferService.getSingleStockTransfer(id)
            .then((res) => {
                dispatch({
                    type: TYPES.GET_SINGLE_SUPPLIER,
                    payload: res.data.supplier,
                });
                dispatch({
                    type: TYPES.SET_PAYMENT_DETAILS,
                    payload: res.data.supplier.account_details
                        ? res.data.supplier.account_details
                        : [],
                });

                // if (status == 'delete') {
                //     dispatch(archive());
                // }

                if (status == 'history') {
                    let id_params = { supplier_id: id };
                    // dispatch(getPurchaseOrders(1, id_params));
                }

                if (!status) {
                    dispatch(setLoading(false));
                }
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    error.data.errors.map((err) => ToastDanger(err));
                } else {
                    ToastDanger('Network error, please reload the page.');
                }

                // $(".btn-save").removeAttr("disabled").html("Save");
                console.log(error);
                // ToastDanger("Network error, please reload the page.");
                // console.log(error);
                if (!status) {
                    dispatch(setLoading(false));
                }
            });
    };

/**************************** =============================== *****************************/

/**************************** STOCK_TRANSFER HISTORY ACTIONS BELOW *****************************/

/**************************** =============================== *****************************/

// // TOGGLE TABS [ PUCHASE ORDERS, PRODUCTS ]
// export const handleChangeTab = (tab, id) => async (dispatch, getState) => {
//     // let { display_tab } = getState().stock_transfer

//     let id_params = { stock_transfer_id: id };

//     switch (tab) {
//         case 'purchase_orders':
//             // dispatch(getPurchaseOrders(1, id_params));
//             break;
//         case 'items':
//             // dispatch(getProductsPaginate(1, id));
//             break;
//         default:
//             break;
//     }
// };

// date input onchange
export const dateFilterInput = (e) => async (dispatch) => {
    dispatch({
        type: TYPES.STOCK_TRANSFER_HISTORY_DATE_FILTER_INPUT,
        payload: { key: [e.target.name], value: e.target.value },
    });
};

// HANDLE SUBMIT DATE FILTER FOR [ PURCHASE ORDER, PRODUCTS ]
export const handleDateFilterSubmit =
    (e, filterBy) => async (dispatch, getState) => {
        e.preventDefault();

        let { date_to, date_from, single_stock_transfer } =
            getState().stock_transfer;

        const today = new Date();

        try {
            if (
                new Date(date_from).getTime() >= today.getTime() ||
                new Date(date_to).getTime() >= today.getTime()
            ) {
                ToastDanger('Your Date value can not be greater than today.');
            } else {
                // console.log('stock_transfer_id:', single_stock_transfer._id);

                let pageOrigin = {
                    stock_transfer_id: single_stock_transfer._id,
                    origin: 'stock_transfer_date_filter',
                };
                let date_filter = { date_from: date_from, date_to: date_to };

                // // CHECK TYPE OF INVOICE
                // switch (filterBy) {
                //     case 'purchase_orders':
                //         dispatch(
                //             getPurchaseOrders(1, null, pageOrigin, date_filter)
                //         );
                //         break;
                //     default:
                //         break;
                // }
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            ToastDanger('Something went wrong please contact your admin...');
        }
    };

// DATE INPUT CLEAR
export const dateFilterClear = (type, id) => async (dispatch) => {
    dispatch({
        type: TYPES.STOCK_TRANSFER_HISTORY_DATE_INPUT_CLEAR,
        payload: type,
    });
    let id_params = { stock_transfer_id: id };

    switch (type) {
        case 'purchase_orders':
            // dispatch(getPurchaseOrders(1, id_params ));
            break;
        case 'items':
            break;
        default:
            break;
    }
};

export const handleAddPayment =
    (payment_details, default_payment_details_) =>
    async (dispatch, getState) => {
        let new_payment_details = payment_details.concat({
            bank_name: '',
            bank_address: '',
            account_number: '',
            account_name: '',
            swift_code: '',
            mode: 'edit',
            has_saved: 0,
        });
        dispatch({
            type: TYPES.SET_PAYMENT_DETAILS,
            payload: new_payment_details,
        });
    };

export const handleRemovePayment =
    (payment_details, key) => async (dispatch, getState) => {
        payment_details = payment_details.filter(
            (item, item_key) => key !== item_key
        );
        dispatch({ type: TYPES.SET_PAYMENT_DETAILS, payload: payment_details });
    };

export const handChangePaymentInput =
    (key, e, payment_details) => async (dispatch, getState) => {
        payment_details[key][e.target.name] = e.target.value;
        dispatch({ type: TYPES.SET_PAYMENT_DETAILS, payload: payment_details });
    };

export const savePayment =
    (e, payment_details, key, mode) => async (dispatch, getState) => {
        e.preventDefault();
        payment_details[key].mode = mode;
        payment_details[key].has_saved = 1;
        dispatch({ type: TYPES.SET_PAYMENT_DETAILS, payload: payment_details });
    };

export const handleRemovePaymentAPI =
    (payment_details, key) => async (dispatch, getState) => {
        if (payment_details[key]._id) {
            dispatch(setLoading(true));
            StockTransferService.supplieAcountDelete({
                _id: payment_details[key]._id,
            })
                .then((res) => {
                    payment_details = payment_details.filter(
                        (item, item_key) => key !== item_key
                    );
                    dispatch({
                        type: TYPES.SET_PAYMENT_DETAILS,
                        payload: payment_details,
                    });
                    dispatch(setLoading(false));
                })
                .catch((error) => {
                    if (error.data.errors.length > 0) {
                        error.data.errors.map((err) => ToastDanger(err));
                    } else {
                        ToastDanger('Network error, please reload the page.');
                    }

                    // $(".btn-save").removeAttr("disabled").html("Save");
                    console.log(error);
                    dispatch(setLoading(false));
                });
        } else {
            payment_details = payment_details.filter(
                (item, item_key) => key !== item_key
            );
            dispatch({
                type: TYPES.SET_PAYMENT_DETAILS,
                payload: payment_details,
            });
        }
    };

export const savePaymentApi =
    (e, payment_details, key, mode, stock_transfer_id) =>
    async (dispatch, getState) => {
        e.preventDefault();
        payment_details[key].mode = mode;
        payment_details[key].has_saved = 1;
        payment_details[key].stock_transfer_id = stock_transfer_id;

        StockTransferService.supplieAcountUpdate(payment_details[key])
            .then((res) => {
                // payment_details  = payment_details.filter((item, item_key) => key !== item_key);
                dispatch({
                    type: TYPES.SET_PAYMENT_DETAILS,
                    payload: payment_details,
                });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    error.data.errors.map((err) => ToastDanger(err));
                } else {
                    ToastDanger('Network error, please reload the page.');
                }

                // $(".btn-save").removeAttr("disabled").html("Save");
                console.log(error);
                // ToastDanger("Network error, please reload the page.");
                // console.log(error);
                dispatch(setLoading(false));
            });

        // dispatch({ type: SET_PAYMENT_DETAILS, payload: payment_details });
    };

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[1],
                    };
                    newArray.push(selected);
                }
            });
        });
        // console.log(newArray);
        dispatch({ type: TYPES.GET_ROWS_TO_DELETE, payload: newArray });
    };

// export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
//     let { rows_to_delete } = getState().customer;
//     let names = '';
//     let name_arr = [];
//     rows_to_delete.map((rows, i) => {
//         name_arr.push('<b>' + rows.name + '</b>');
//     });
//     names = name_arr.join(', ');

//     SwalWarningHtml(
//         'Warning!',
//         `Do you want to remove the StockTransfer(s): ${names}?`,
//         () => dispatch(moveToArchives())
//     );
// };

// Sort by column name and direction
export const sortTableByColumn =
    (sort_order_name, sort_order_direction) => async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: TYPES.SORT_BY_COLUMN, payload: sortingParams });
        dispatch(getStockTransfers());
    };

export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: TYPES.SEARCH_INPUT, payload: e.target.value });
    if (e.key === 'Enter') {
        console.log('enter');
        // dispatch(getPurchaseOrders(1));
    }
};

// set the rows checked box
export const setRowChecked = () => async (dispatch) =>
    dispatch({ type: TYPES.SET_ROW_CHECKED });

// Set Modal
export const setModal = (modal, status) => async (dispatch) => {
    if (modal === 'multi_stock_transfer_modal') {
        dispatch({
            type: TYPES.SET_MULTI_TRANSFER,
            payload: true,
        });
    }
    dispatch({
        type: TYPES.SET_MODAL,
        payload: { modal, status },
    });
};

export const resetModalInputs = () => (dispatch) => {
    dispatch({
        type: TYPES.RETURN_CLEAR,
        payload: {
            qty: 0,
            remarks: '',
            supplier: null,
            transfer_item: [],
        },
    });
};

// handle qty item stock_transfer
export const updateQty = (status) => async (dispatch, getState) => {
    const { transfer_item } = getState().stock_transfer;
    const qty = transfer_item[0].qty;
    let payload = status == 'inc' ? qty + 1 : qty == 0 ? qty : qty - 1;

    dispatch({ type: TYPES.STOCK_TRANSFER_UPDATE_QTY, payload });
};

// search single item
export const searchSubmit =
    (e, status = null) =>
    async (dispatch, getState) => {
        e.preventDefault();

        const { search } = getState().stock_transfer;

        // Validations
        if (search == '') {
            return ToastDanger('Search field is required');
        }

        const params = { search };

        dispatch(searchProduct(params));
    };

export const getProducts = (products) => async (dispatch) => {
    dispatch({
        type: TYPES.STOCK_TRANSFER_GET_PRODUCTS,
        payload: { products: products },
    });
    dispatch(setModal('stock-transfer_item_modal'));
};

// search dynamic values on product
export const searchProduct =
    (postParams, origin = null) =>
    async (dispatch, getState) => {
        dispatch(setLoading('transfer-search'));

        let { transfer_item, multi_transfer } = getState().stock_transfer;

        StockTransferService.scanBarcode(postParams)
            .then((res) => {
                if (res.status === 200) {
                    const scannedItem = res.data;

                    if (!scannedItem.inStock) {
                        dispatch(setLoading('#'));
                        return ToastDanger(
                            `${scannedItem.product_name} is out of stock`
                        );
                    }

                    scannedItem.product_image =
                        scannedItem.product_image || '/noimage.jpg';
                    scannedItem.qty = 1; // Set default qty as 1 for each scan

                    if (multi_transfer) {
                        // Show each scan separately in the modal
                        let updatedItems = [...transfer_item, scannedItem];

                        dispatch({
                            type: TYPES.STOCK_TRANSFER_GET_SCAN,
                            payload: updatedItems,
                        });
                    } else {
                        // Single scan mode - replace previous entry
                        dispatch(removeScan());
                        dispatch({
                            type: TYPES.STOCK_TRANSFER_GET_SCAN,
                            payload: [scannedItem],
                        });
                    }

                    dispatch(setLoading('#'));
                }
            })
            .catch((error) => {
                dispatch(setLoading('#'));
                if (error.data?.message) {
                    return ToastDanger(error.data.message);
                }
                console.error(error);
            });
    };

//  REMOVE SCAN DATA
export const removeScan = () => async (dispatch, getState) => {
    let { transfer_item } = getState().stock_transfer;

    if (transfer_item.length > 0) {
        transfer_item.shift(); // if the transfer_item has value remove the first
    }

    dispatch({ type: TYPES.STOCK_REMOVE_SCAN, payload: transfer_item });
};

//** SUBMIT TRANSFER */
export const submitStockTransfer =
    (movement, callback = () => {}) =>
    async (dispatch, getState) => {
        const { transfer_item, remarks, multi_transfer } =
            getState().stock_transfer;

        if (transfer_item.length === 0) {
            return ToastDanger('Please scan an Item first');
        }

        // Merge identical items before submitting
        const mergedItems = transfer_item.reduce((acc, item) => {
            const existingItem = acc.find(
                (i) => i.sku === item.sku || i.barcode === item.barcode
            );

            if (existingItem) {
                existingItem.qty += item.qty; // Add quantity if the same item exists
            } else {
                acc.push({ ...item });
            }

            return acc;
        }, []);

        let params = {
            movement,
            remarks,
            transfer_item: mergedItems, // Send merged items
        };

        try {
            $('.btn-trans')
                .attr('disabled', 'disabled')
                .html(
                    '<span class="spinner-border" role="status" aria-hidden="true"></span>'
                );

            const res = await StockTransferService.submitStockTransfer(params);

            ToastSuccess(res.data.message);
            dispatch(getStockTransfers(1));
            $('.btn-in').removeAttr('disabled').html('In');
            $('.btn-out').removeAttr('disabled').html('Out');
            dispatch(removeScan());
            dispatch(resetModalInputs());

            callback();
        } catch (err) {
            if (err.data?.errors) {
                ToastDanger(err.data.errors);
            }
            console.error(err);
            $('.btn-in').removeAttr('disabled').html('In');
            $('.btn-out').removeAttr('disabled').html('Out');
            callback();
        }
    };

// Search supplier
export const SearchSupplier = (search) => async (dispatch) =>
    new Promise((resolve, reject) => {
        const formdata = {
            search: search,
        };

        // MODIFIED TO CANCEL PREVIOuS REQUEST PER SEARCH - ASK LUKE FOR ASSISTANCE
        PurchaseOrderService.getSupplier(formdata)
            .then((res) => {
                let supplier_arr = [];
                let suppliers = res.data.suppliers;

                suppliers.map((val, i) => {
                    let select = {
                        value: val._id,
                        label: val.name,
                        name: 'supplier_id',
                    };
                    supplier_arr[i] = select;
                });
                resolve(supplier_arr);
                // dispatch({ type: GET_SI_CUSTOMERS, payload: supplier_arr });
            })
            // .then((res) => {
            //     // console.log(res.data.suppliers.data);
            //     let createOption = createSelectOption(res.data.suppliers.data);
            //     dispatch({ type: GET_PO_SUPPLIERS, payload: createOption });
            //     dispatch(setLoading(false));
            //     callback(createOption);
            //     return createOption;
            // })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });

// get the supplier
const getDisplaySupplier = (id) => async (dispatch) => {
    PurchaseOrderService.getDisplaySupplier(id)
        .then((res) => {
            dispatch({
                type: TYPES.STOCK_TRANSFER_SUPPLIER,
                payload: res.data.supplier,
            });
        })
        .catch((error) => {
            if (error.data.errors.length > 0) {
                ToastDanger(error.data.errors);
            } else {
                ToastDanger('Network error, please reload the page.');
            }
            console.log(error);
        });
};

// export const set_items =
//     (items, _new = true) =>
//     async (dispatch, getState) => {
//         let { single_purchase_order } = getState().purchase_order;
//         let item_template = {
//             _id: '',
//             items: items,
//             sku: '',
//             product_name: '',
//             // product_unit: { name: '' },
//             attribute_string: '',
//             qty: 0,
//             cost: 0,
//             amount: 0,
//             _new: true,
//         };

//         if (_new) {
//             let data = (single_purchase_order.purchase_order_items = [
//                 item_template,
//             ]);
//             dispatch({ type: TYPES.SET_PO_ITEMS, payload: data });
//         }

//         dispatch({ type: TYPES.PO_ITEMS_TEMPLATE, payload: item_template });
//     };

// get the supplier's items
const getItemSupplier =
    (id, _new = false, supplier_item_only = null) =>
    async (dispatch) => {
        dispatch(setLoading(true));
        PurchaseOrderService.getItemSupplier(id)
            .then((res) => {
                dispatch(setLoading(false));
                let items = res.data.products;

                if (!items) {
                    ToastWarning(
                        'Supplier does not have item in the system. please add at least one item.'
                    );

                    let items_arr = [];
                    // dispatch({ type: TYPES.SET_PO_ITEMS, payload: items_arr });
                } else {
                    let items_arr = [];
                    items = [];
                    // console.log("items", items);
                    items.map((item, i) => {
                        if (item.product != null) {
                            let attribute = '';
                            try {
                                if (
                                    item.product.attribute_string == '()' ||
                                    item.product.attribute_string == null ||
                                    item.product.attribute_string == ''
                                ) {
                                    attribute = '';
                                } else {
                                    let attr_replace_char =
                                        item.product.attribute_string
                                            .replace('(', '')
                                            .replace(')', '');
                                    attribute =
                                        attr_replace_char.slice(0, 40) +
                                        (attr_replace_char.length > 40
                                            ? '...'
                                            : '');
                                }
                            } catch (err) {}

                            let select = {
                                _id: item.product._id,
                                value: item.product._id,
                                label: item.product.product_name,
                                sub_label: attribute,
                                barcode: item.product.barcode,
                                brand: item.product.brand,
                                product_name: item.product.product_name,
                                product_id: item.product._id,
                                sku: item.product.sku,
                                qty: 1,
                                product_unit_id: item.product.product_unit_id
                                    ? item.product.product_unit_id
                                    : null,
                                product_unit_name: item.product.product_unit
                                    ? item.product.product_unit.name
                                    : '',
                                attribute_string:
                                    item.product.attribute_string == '()'
                                        ? null
                                        : item.product.attribute_string,
                                cost: item.cost,
                                amount: item.cost,
                                description: item.product.description,
                                attribute_template:
                                    item.product.attribute_template,
                                lot_no:
                                    item.single_lot != null &&
                                    item.single_lot != undefined
                                        ? item.single_lot.lot_no
                                        : '',
                                status_code:
                                    item.product.status_code != null
                                        ? item.product.status_code
                                        : 0,
                                i: i,
                            };

                            items_arr[i] = select;
                        }
                    });

                    // console.log(items_arr);
                    //console.log("here");
                    dispatch({
                        type: TYPES.STOCK_GET_SUPPLIER_ITEMS,
                        payload: items_arr,
                    });

                    // dispatch({type : SET_PO_ITEMS, payload : items_arr});
                    // dispatch(set_items(items_arr, _new));
                }
            })
            .catch((error) => {
                // if (error.data.errors.length > 0) {
                //     ToastDanger(error.data.errors);
                // } else {
                //     ToastDanger('Network error, please reload the page.');
                // }

                console.log(error);
            });
    };

// Handle Select Supplier
export const handleSelectSupplier =
    (selectOption, supplier_item_only = null) =>
    async (dispatch) => {
        if (selectOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        // console.log(supplier_id, supplier, items);
        dispatch({
            type: TYPES.HANDLE_STOCK_SELECT_SUPPLIER,
            payload: selectOption,
        });

        dispatch(getDisplaySupplier(selectOption.value));
        dispatch(getItemSupplier(selectOption.value, true, supplier_item_only));
    };
